export * from "@secureo/common/config/contact"

// Social Media
export const socialMediaPages = {
	facebook: "https://www.facebook.com/digitalzylindershop",
	instagram: "https://www.instagram.com/digitalzylindershop",
	youtube: "https://www.youtube.com/channel/UCGixSI8PSLCzAozZKaV-s9w",
}

// Email
export const emailAddress = "office@digitalzylinder-shop.com"
