import React, { useContext } from "react"
import dynamic from "next/dynamic"
import { Container, Box, Grid, Hidden } from "@mui/material"

import Header from "./Header"
import CheckoutHeader from "./CheckoutHeader"
import AccountDesktopNavigation from "containers/Account/AccountDesktopNavigation"
import Footer from "./Footer"
import BackToTop from "../BackToTop"
const LoginRegister = dynamic(() => import("../Login/LoginRegister"), { ssr: false })
const CookieBanner = dynamic(() => import("components/CookieBanner"), { ssr: false })

import AppContext from "@secureo/common/context/AppContext/AppContext"
import AuthenticatedPage from "./AuthenticatedPage"

import { CountryCode } from "@secureo/common/typings/CountryCode"
import { Fragment } from "react"

const Layout = ({ children, asPath, locale, countryCode }: Props) => {
	const { loginRegisterFormIsOpen, openLoginRegisterFormTab, dispatch } = useContext(AppContext)

	const isAccount = asPath?.includes("account") ?? false
	const isCheckout = asPath?.includes("checkout") ?? false
	const isLockingSystemConfiguratorPage =
		asPath?.includes("locking-system/configure/") || asPath?.includes("locking-system/id/")
	const isProductDetailPage = asPath?.includes("/p/") ?? false

	return (
		<Fragment>
			{!isLockingSystemConfiguratorPage ? (
				<>
					{!isCheckout ? <Header locale={locale} /> : <CheckoutHeader />}
					<main
						style={{
							minHeight: "55vh",
						}}
					>
						{!isAccount ? (
							<>{children}</>
						) : (
							<AuthenticatedPage>
								<Container>
									<Box mt={2} mb={5}>
										<Grid container spacing={3}>
											<Hidden lgDown>
												<Grid item xs={12} md={3}>
													<AccountDesktopNavigation locale={locale} />
												</Grid>
											</Hidden>
											<Grid item xs={12} md={9}>
												{children}
											</Grid>
										</Grid>
									</Box>
								</Container>
							</AuthenticatedPage>
						)}
					</main>
					<Footer countryCode={countryCode} />
				</>
			) : (
				<main
					style={{
						minHeight: "55vh",
					}}
				>
					{children}
				</main>
			)}
			{!isProductDetailPage && <BackToTop />}
			<CookieBanner />

			{loginRegisterFormIsOpen && (
				<LoginRegister
					loginRegisterFormIsOpen={loginRegisterFormIsOpen}
					openLoginRegisterFormTab={openLoginRegisterFormTab}
					dispatch={dispatch}
					locale={locale}
				/>
			)}
		</Fragment>
	)
}

interface Props {
	children: React.ReactNode
	asPath: string
	locale: string
	countryCode: CountryCode
}

export default Layout
