import { CountryCode } from "@secureo/common/typings/CountryCode"

export const getFallbackMetaTitle = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return "Digitalzylinder Shop Österreich - Elektronische Türschlösser & Schließzylinder"
		case "CH":
			return "Digitalzylinder Shop Schweiz - Elektronische Türschlösser & Schließzylinder"
		default:
			return "Digitalzylinder Shop Deutschland - Elektronische Türschlösser & Schließzylinder"
	}
}

export const getFallbackMetaDescription = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return "Elektronische Türschlösser, Schließzylinder und Smart Locks versandkostenfrei und günstig kaufen: ✓ Alle Qualitäts-Hersteller ✓ Große Auswahl ✓ Top-Preise"
		case "CH":
			return "Elektronische Türschlösser, Schließzylinder und Smart Locks versandkostenfrei und günstig kaufen: ✓ Alle Qualitäts-Hersteller ✓ Große Auswahl ✓ Top-Preise"
		default:
			return "Elektronische Türschlösser, Schließzylinder und Smart Locks versandkostenfrei und günstig kaufen: ✓ Alle Qualitäts-Hersteller ✓ Große Auswahl ✓ Top-Preise"
	}
}
