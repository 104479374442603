import * as klarnaPaymentsSDK from "@secureo/common/utils/payments/klarnaPayments"
import getKlarnaSession, {
	getInitialKlarnaSession,
} from "@secureo/common/utils/payments/klarnaSession"

import { CheckoutContextState } from "../CheckoutContext"
import { CountryCode } from "@secureo/common/typings/CountryCode"
import { Cart } from "@secureo/common/typings/Cart"

// Klarna Payment API
export const initializeKlarnaSession = async (
	host: string,
	cart: Cart,
	checkoutContext: CheckoutContextState,
	shopCountryCode: CountryCode,
	language: string,
) => {
	console.info("Initializing klarna session")

	try {
		const { dispatch } = checkoutContext

		// TODO: Find reasonable defaults for country and locale
		const session = getInitialKlarnaSession(host, cart, shopCountryCode, language)

		const payload = await klarnaPaymentsSDK.initialize(session)

		dispatch({
			type: "INITIALIZE_KLARNA_PAYMENTS_SESSION",
			payload: payload,
		})
	} catch (err) {
		console.error(err)
	}
}

export const loadKlarnaWidget = async (
	cart: Cart,
	checkoutContext: CheckoutContextState,
	paymentCategoryIdentifier: string,
	host: string,
) => {
	const { billingAddress, shippingAddress, shippingAddressDiffers, dispatch } = checkoutContext

	dispatch({
		type: "SET_IS_LOADING",
		payload: true,
	})

	const session = getKlarnaSession(
		cart,
		billingAddress,
		shippingAddress,
		shippingAddressDiffers,
		host,
	)

	const payload = await klarnaPaymentsSDK.load(session, paymentCategoryIdentifier)

	dispatch({
		type: "UPDATE_KLARNA_PAYMENTS_SESSION",
		payload: {
			...payload,
			initializedPaymentMethod: paymentCategoryIdentifier,
		},
	})
	dispatch({
		type: "SET_IS_LOADING",
		payload: false,
	})
}

export const authorizeKlarnaSession = async (
	cart: Cart,
	checkoutContext: CheckoutContextState,
	host: string,
) => {
	const {
		billingAddress,
		shippingAddress,
		shippingAddressDiffers,
		dispatch,
		selectedPaymentMethod,
	} = checkoutContext

	dispatch({
		type: "SET_IS_LOADING",
		payload: true,
	})

	const session = getKlarnaSession(
		cart,
		billingAddress,
		shippingAddress,
		shippingAddressDiffers,
		host,
	)

	try {
		const payload = await klarnaPaymentsSDK.authorize(session, selectedPaymentMethod)

		dispatch({
			type: "UPDATE_KLARNA_PAYMENTS_SESSION",
			payload,
		})
		const isApproved = payload.approved
		return isApproved
	} catch (err) {
		console.error(err)
		return false
	}
}

export const createKlarnaOrder = async (checkoutContext: CheckoutContextState, cartId: string) => {
	console.info("Creating klarna order")
	const { klarnaPayments, selectedPaymentMethod, dispatch } = checkoutContext

	const { session_id } = klarnaPayments

	const payload = await klarnaPaymentsSDK.createKlarnaOrder(
		session_id,
		cartId,
		selectedPaymentMethod,
	)

	dispatch({
		type: "UPDATE_KLARNA_PAYMENTS_SESSION",
		payload: payload,
	})

	return payload.redirect_url
}
