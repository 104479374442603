import { checkVAT, countries, switzerland } from "jsvat"

import { CountryCode } from "@secureo/common/typings/CountryCode"

const countryCodesExcludedFromValidation: CountryCode[] = ["CH", "LI"]

const getJSVatCountryByCountryCode = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "LI":
			return switzerland
		default:
			return countries.find(({ codes }) => codes.includes(countryCode))
	}
}

const isValidVatId = (vatId: string, countryCode: CountryCode) => {
	if (countryCodesExcludedFromValidation.includes(countryCode)) return true

	const jsVatCountry = getJSVatCountryByCountryCode(countryCode)

	const validationResult = checkVAT(vatId, [jsVatCountry])
	const { isValid } = validationResult

	return isValid
}

export default isValidVatId
