import React from "react"
import { Box } from "@mui/material"

import removeWhiteSpace from "@secureo/common/utils/removeWhiteSpace"

import CountryFlag from "./CountryFlag"
import Europe from "@secureo/common/svg/flags/Europe.svg"

import { CountryCode } from "@secureo/common/typings/CountryCode"
import { fallBackPhoneNumber, phoneNumbers, emailAddress } from "config/contact"

export const getLocalizedPhoneNumber = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "DE":
			return phoneNumbers.DE
		case "AT":
			return phoneNumbers.AT
		default:
			return fallBackPhoneNumber
	}
}

export const Phone = ({ countryCode, children }: PhoneProps) => {
	const phoneNumber = getLocalizedPhoneNumber(countryCode)

	return (
		<div>
			{countryCode !== "CH" && (
				<a href={`tel:${removeWhiteSpace(phoneNumber)}`} className="link" data-hj-whitelist>
					<Box style={{ display: "flex", alignItems: "center" }}>
						<Box
							style={{
								width: "20px",
								height: "100%",
								paddingTop: 4,
								marginRight: 5,
							}}
						>
							<CountryFlag countryCode={countryCode} />
						</Box>{" "}
						{children || phoneNumber}
					</Box>
				</a>
			)}

			<a
				href={`tel:${removeWhiteSpace(fallBackPhoneNumber)}`}
				className="link"
				data-hj-whitelist
			>
				<Box style={{ display: "flex", alignItems: "center" }}>
					<Box
						style={{
							width: "20px",
							height: "100%",
							marginRight: 5,
						}}
					>
						<Europe />
					</Box>
					{children || fallBackPhoneNumber}
				</Box>
			</a>
		</div>
	)
}

interface PhoneProps {
	countryCode: CountryCode
	children?: React.ReactNode
}

export const Email = ({ children }: EmailProps) => {
	return (
		<a href={`mailto:${emailAddress}`} className="link" data-hj-whitelist>
			{children || emailAddress}
		</a>
	)
}

interface EmailProps {
	children?: React.ReactNode
}
