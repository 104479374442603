import React from "react"
import { Box, LinearProgress, linearProgressClasses } from "@mui/material"
import { styled } from "@mui/material/styles"

import LazyLoadedImage from "components/LazyLoadedImage"
import Link from "components/i18n/Link"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: "#1a90ff",
	},
}))

const NavCategoryPreview = ({
	name,
	slug,
	base64BrandIcon,
	svgIconNames,
	securityScore,
	categoryProductImageUrl,
	previewBulletPoints = [],
	closeNavbar,
}: Props) => {
	return (
		<Box
			pt={2}
			p={4}
			style={{
				background: "white",
				boxShadow: "none",
				borderBottomRightRadius: 9,
				borderBottomLeftRadius: 0,
				border: "1px solid #E7EFF6",
				borderTop: "none",
				width: 350,
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					{svgIconNames.map((svgIconName) => {
						return (
							<Box mr={1} key={svgIconName}>
								<img
									src={`/assets/navbar/${svgIconName}.svg`}
									alt={svgIconName}
									style={{ height: 35, width: 35 }}
								/>
							</Box>
						)
					})}
				</div>
				<img
					src={base64BrandIcon}
					alt="Herstellerlogo"
					style={{
						height: "100px",
						maxHeight: 60,
						width: "100px",
						objectFit: "contain",
						position: "absolute",
						top: 8,
						right: -55,
					}}
				/>
			</div>
			<div
				style={{
					textAlign: "center",
				}}
			>
				<Link href={`/c/${slug}`} onClick={closeNavbar}>
					<Box
						my={3}
						style={{
							height: 200,
							width: 200,
							background: "whitesmoke",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					>
						<LazyLoadedImage
							src={categoryProductImageUrl}
							alt={name}
							title={name}
							style={{ width: "100%", maxHeight: 300 }}
						/>
					</Box>
				</Link>
			</div>

			<Box my={2}>
				<Link href={`/c/${slug}`} onClick={closeNavbar}>
					<strong>{name}</strong>
				</Link>
			</Box>

			{previewBulletPoints.length > 0 && (
				<ul>
					{previewBulletPoints.map((bulletPoint) => {
						return (
							<li key={bulletPoint}>
								<span style={{ color: "#4591CE" }}>•</span> {bulletPoint}
							</li>
						)
					})}
				</ul>
			)}

			{securityScore && (
				<>
					<Box pt={2} my={1}>
						<strong>Sicherheitsstufe: {securityScore} / 10</strong>
					</Box>
					<BorderLinearProgress variant="determinate" value={securityScore * 10} />
				</>
			)}
		</Box>
	)
}

interface Props {
	name: string
	slug: string
	svgIconNames: string[]
	base64BrandIcon: string
	categoryProductImageUrl: string
	securityScore: number
	previewBulletPoints: string[]
	closeNavbar: () => void
}

export default NavCategoryPreview
