import React from "react"
import { makeStyles } from "makeStyles"
import useScrollPosition from "@secureo/common/hooks/useScrollPosition"

import Icon from "@mdi/react"
import { mdiArrowUpBoldOutline } from "@mdi/js"

const useStyles = makeStyles()({
	backToTopButton: {
		position: "fixed",
		bottom: "20px",
		right: "20px",
		width: "50px",
		height: "50px",
		background: "#4691CE",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "3px",
		border: "none",
		outline: "none",
		zIndex: 3,
		boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.15)",
		cursor: "pointer",
	},
	dNone: {
		display: "none",
	},
})

const BackToTop = () => {
	const { classes } = useStyles()
	const { scrollY } = useScrollPosition()

	const scrollBackToTopHandler = () => {
		window.scrollTo(0, 0)
	}

	return (
		<div className={scrollY > 700 ? "" : classes.dNone}>
			<button className={classes.backToTopButton} onClick={scrollBackToTopHandler}>
				<Icon path={mdiArrowUpBoldOutline} size={1} color="#f7fafa" />
			</button>
		</div>
	)
}

export default BackToTop
