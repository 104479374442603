import React, { Fragment, useState } from "react"
import { Container, Box } from "@mui/material"
import { makeStyles } from "makeStyles"
import Icon from "@mdi/react"
import { mdiMenu } from "@mdi/js"

import NavCategoryPreview from "./NavCategoryPreview"
import Link from "components/i18n/Link"
import HelpPopoverDesktop from "./HelpPopoverDesktop"
import SwitchCountry from "../i18n/SwitchCountry"

import { NavCategory } from "@secureo/common/utils/commerceTools/graphql/queries/fetchNavCategories"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const useStyles = makeStyles()({
	navLink: {
		color: "white",
		height: 32,
		paddingTop: 8,
		paddingLeft: 10,
		paddingRight: 10,
		marginTop: 0,
		transition: "0.4s",
		transitionDelay: "0.08s",
		"&:hover": {
			background: "#4691CE",
		},
	},
})

const NestedNavCategory = ({ category, closeNavbar }: NestedNavCategoryProps) => {
	const [isOpen, setIsOpen] = useState(false)

	const openCategoryPreview = () => {
		setIsOpen(true)
	}

	const closeCategoryPreview = () => {
		setIsOpen(false)
	}

	const {
		name,
		slug,
		base64BrandIcon,
		categoryProductImage,
		securityScore,
		base64PreviewIcons,
		previewBulletPoints,
	} = category

	return (
		<li onMouseEnter={openCategoryPreview} onMouseLeave={closeCategoryPreview}>
			<div className="menu-link menu-list-link" aria-haspopup="true" role="button">
				<Link
					href={`/c/${slug}`}
					onClick={closeNavbar}
					style={{ height: 22, maxHeight: "auto", whiteSpace: "nowrap" }}
				>
					{name}
				</Link>
			</div>

			{categoryProductImage && isOpen && (
				<ul className="menu menu-list">
					<li>
						<NavCategoryPreview
							name={name}
							slug={slug}
							closeNavbar={closeNavbar}
							svgIconNames={base64PreviewIcons}
							base64BrandIcon={base64BrandIcon}
							categoryProductImageUrl={categoryProductImage}
							securityScore={securityScore}
							previewBulletPoints={previewBulletPoints}
						/>
					</li>
				</ul>
			)}
		</li>
	)
}

interface NestedNavCategoryProps {
	category: NavCategory
	closeNavbar: () => void
}

const NavCategoryList = ({ category, closeNavbar }: NavCategoryListProps) => {
	const [isOpen, setIsOpen] = useState(false)

	const openCategoryChildren = () => {
		setIsOpen(true)
	}

	const closeCategoryChildren = () => {
		setIsOpen(false)
	}

	const { name, slug, children = [] } = category

	return (
		<li onMouseEnter={openCategoryChildren} onMouseLeave={closeCategoryChildren}>
			<Link
				href={`/c/${slug}`}
				className="menu-link mega-menu-link"
				aria-haspopup="true"
				style={{
					padding: "9px 25px",
				}}
				onClick={closeNavbar}
			>
				{name}
			</Link>

			{isOpen && (
				<ul className="menu menu-list">
					{children.map((childCategory) => {
						const { name, slug, children = [] } = childCategory

						return (
							<Fragment key={slug}>
								<Box ml={3} py={1}>
									{name.includes("Alle") ? (
										<div
											style={{
												color: "#263739",
												height: 25,
											}}
										>
											<strong>{name}</strong>
										</div>
									) : (
										<Link
											href={`/c/${slug}`}
											style={{
												color: "#263739",
												height: 25,
											}}
											onClick={closeNavbar}
										>
											{name}
										</Link>
									)}
								</Box>

								{children.map((childCategory) => {
									return (
										<NestedNavCategory
											key={childCategory.slug}
											category={childCategory}
											closeNavbar={closeNavbar}
										/>
									)
								})}
							</Fragment>
						)
					})}
				</ul>
			)}
		</li>
	)
}

interface NavCategoryListProps {
	category: NavCategory
	closeNavbar: () => void
}

const NavigationDesktop = ({ navCategories, countryCode }: Props) => {
	const { classes } = useStyles()
	const [isOpen, setIsOpen] = useState(false)

	const openNavbar = () => {
		setIsOpen(true)
	}

	const closeNavbar = () => {
		setIsOpen(false)
	}

	return (
		<div style={{ background: "#263739" }}>
			<Container className="navbar-new">
				<div className="nav">
					<nav>
						<ul className="menu menu-bar">
							<li onMouseEnter={openNavbar} onMouseLeave={closeNavbar}>
								<div
									className="menu-link menu-bar-link"
									aria-haspopup="true"
									role="button"
									style={{
										margin: 0,
										paddingLeft: 15,
										paddingRight: 15,
										paddingTop: 8,
										paddingBottom: 8,
										width: 216,
										background:
											"linear-gradient(90deg, rgba(59,121,171,1) 0%, rgba(70,145,206,1) 53%, rgba(90,164,224,1) 100%)",
										color: "white",
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									Alle Produkte <Icon path={mdiMenu} size={1} color="white" />
								</div>

								{isOpen && (
									<ul className="mega-menu mega-menu--multiLevel">
										{navCategories.map((category) => {
											return (
												<NavCategoryList
													key={category.slug}
													category={category}
													closeNavbar={closeNavbar}
												/>
											)
										})}
									</ul>
								)}
							</li>

							<li>
								<Link href="/locking-system">
									<Box mt={1} className={classes.navLink}>
										Schließanlage planen
									</Box>
								</Link>
							</li>
							<li>
								<Link href="/c/schluessel-nachmachen">
									<Box mt={1} className={classes.navLink}>
										Nachbestellungen
									</Box>
								</Link>
							</li>
							{/* <li>
								<Link href="/locking-system-advisor">
									<Box mt={1} className={classes.navLink}>
										System finden
									</Box>
								</Link>
							</li> */}
							<li>
								<Box style={{ marginTop: 0 }}>
									<HelpPopoverDesktop />
								</Box>
							</li>
							<li style={{ position: "absolute", right: 0 }}>
								<Box
									style={{
										color: "white",
										display: "flex",
										alignItems: "center",
									}}
								>
									<SwitchCountry countryCode={countryCode} />
								</Box>
							</li>
						</ul>
					</nav>
				</div>
			</Container>
		</div>
	)
}

interface Props {
	navCategories: NavCategory[]
	countryCode: CountryCode
}

export default NavigationDesktop
