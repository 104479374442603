import { useState } from "react"
import {
	trackGA4ViewListItem,
	trackProductHitImpression,
} from "@secureo/common/utils/tracking/productHit"

import { AlgoliaProductHit } from "@secureo/common/typings/Algolia"

const useProductHitImpressionTracking = (
	productHit: Pick<AlgoliaProductHit, "name" | "grossPriceCents" | "manufacturer">,
	categoryName: string,
	listName: string,
	position: number,
) => {
	const [isTracked, setIsTracked] = useState(false)

	const { name, grossPriceCents, manufacturer } = productHit

	const trackImpression = () => {
		if (!isTracked) {
			trackProductHitImpression(
				name,
				grossPriceCents,
				manufacturer,
				categoryName,
				listName || categoryName,
				position,
			)
			trackGA4ViewListItem(
				name,
				grossPriceCents,
				manufacturer,
				categoryName,
				listName || categoryName,
				position,
			)

			setIsTracked(true)
		}
	}

	return trackImpression
}

export default useProductHitImpressionTracking
