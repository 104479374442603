import validateFormAddress from "@secureo/common/utils/validation/validateFormAddress"
import setCustomerInformationToCart from "../utils/setCustomerInformationToCart"
import processPayment from "../utils/processPayment"

import { AppContextState } from "../../AppContext/AppContext"
import { CheckoutContextState, DispatchCheckoutContext } from "../CheckoutContext"

import { Cart } from "@secureo/common/typings/Cart"
import { addDiscountCodeToCart } from "../../AppContext/actions"

const setIsLoading = (dispatch: DispatchCheckoutContext, isLoading = true) => {
	dispatch({
		type: "SET_IS_LOADING",
		payload: isLoading,
	})
}

const setIsInCheckoutFlow = (dispatch: DispatchCheckoutContext, isInCheckoutFlow = true) => {
	dispatch({
		type: "SET_IS_IN_CHECKOUT_FLOW",
		payload: isInCheckoutFlow,
	})
}

const showCheckoutErrorFeedback = (
	enqueueSnackbarTranslatedSnackbarWarning: (i18nKey: string) => void,
	i18nKey: "checkAddressData" | "somethingWentWrong",
) => {
	window.scrollTo(0, 0)
	enqueueSnackbarTranslatedSnackbarWarning(i18nKey)
}

export const checkout = async (
	cart: Cart,
	checkoutContext: CheckoutContextState,
	appContext: AppContextState,
	enqueueSnackbarTranslatedSnackbarWarning: (i18nKey: string) => void,
	host: string,
	setIsPrepaidDiscounted: React.Dispatch<React.SetStateAction<boolean>>,
) => {
	const { billingAddress, shippingAddress, dispatch } = checkoutContext
	const { language = "de", countryCode } = appContext
	const locale = `${language}-${countryCode}`

	//  Klarna requires us to hide Klarna payments if the customer is not approved
	const hideKlarnaPayments = async () => {
		dispatch({
			type: "SET_IS_LOADING",
			payload: true,
		})

		dispatch({
			type: "SET_PAYMENT_METHOD",
			payload: "prepaid",
		})

		await addDiscountCodeToCart(
			appContext.accessToken,
			appContext.dispatch,
			cart,
			language,
			"prepaid",
			appContext.countryCode,
			shippingAddress.country,
			true,
			billingAddress.isCompany,
			shippingAddress.vatId,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			() => {
				return null as any
			},
		)
		setIsPrepaidDiscounted(true)

		dispatch({
			type: "HIDE_KLARNA_PAYMENTS",
		})

		dispatch({
			type: "SET_IS_LOADING",
			payload: false,
		})
	}

	try {
		const { accessToken } = appContext
		let updatedCart = cart

		setIsLoading(dispatch)
		setIsInCheckoutFlow(dispatch)

		// Validate shippingAddress
		const shippingAddressValidation = validateFormAddress(shippingAddress)
		const { isValid: isValidShippingAddress } = shippingAddressValidation
		if (!isValidShippingAddress) {
			dispatch({
				type: "SET_SHIPPING_ADDRESS_ERRORS",
				payload: shippingAddressValidation.errors,
			})
		}

		// Validate billingAddress
		const billingAddressValidation = validateFormAddress(billingAddress)
		const { isValid: isValidBillingAddress } = billingAddressValidation
		if (!isValidBillingAddress) {
			dispatch({
				type: "SET_BILLING_ADDRESS_ERRORS",
				payload: billingAddressValidation.errors,
			})
		}

		if (!isValidShippingAddress || !isValidBillingAddress) {
			setIsLoading(dispatch, false)
			setIsInCheckoutFlow(dispatch, false)
			showCheckoutErrorFeedback(enqueueSnackbarTranslatedSnackbarWarning, "checkAddressData")
			return
		}

		updatedCart = await setCustomerInformationToCart(appContext, checkoutContext)

		// Ensure prepaid discount is added when prepaid paymentMethod is selected
		const isPrepaidPayment = checkoutContext?.selectedPaymentMethod === "prepaid"
		const cartHasPrepaidDiscount = !!updatedCart?.discountCodes?.find(
			(discountCode) => discountCode?.discountCode?.code === "prepaid",
		)
		if (isPrepaidPayment && !cartHasPrepaidDiscount) {
			updatedCart = await addDiscountCodeToCart(
				accessToken,
				appContext.dispatch,
				cart,
				language,
				"prepaid",
				appContext.countryCode,
				shippingAddress.country,
				true,
				billingAddress.isCompany,
				shippingAddress.vatId,
			)
		}

		const redirectUrl = await processPayment(
			checkoutContext,
			updatedCart,
			accessToken,
			locale,
			host,
			hideKlarnaPayments,
		)

		if (!redirectUrl) {
			setIsLoading(dispatch, false)
			setIsInCheckoutFlow(dispatch, false)
			showCheckoutErrorFeedback(
				enqueueSnackbarTranslatedSnackbarWarning,
				"somethingWentWrong",
			)
			return
		}

		// Redirect either to /success or payment processor page
		window.location.replace(redirectUrl)
	} catch (err) {
		console.error(err)
		setIsLoading(dispatch, false)
		setIsInCheckoutFlow(dispatch, false)
		showCheckoutErrorFeedback(enqueueSnackbarTranslatedSnackbarWarning, "somethingWentWrong")
	}
}
