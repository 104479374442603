import updateCart from "@secureo/common/utils/commerceTools/graphql/mutations/updateCart"
import { getCommerceToolsGraphQLAddressInputFromFormAddress } from "@secureo/common/utils/commerceTools/formatters/formatAddress"
import { dispatchUpdatedCart } from "../../AppContext/actions"

import {
	MyCartUpdateAction,
	SetCartBillingAddress,
	SetCartShippingAddress,
	SetCartCustomerEmail,
	SetCartCustomField,
} from "@secureo/common/typings/generated/commercetools-graphql"
import { AppContextState } from "../../AppContext/AppContext"
import { CheckoutContextState } from "../CheckoutContext"

const setCustomerInformationToCart = async (
	appContext: AppContextState,
	checkoutContext: CheckoutContextState,
) => {
	const { accessToken, cart, dispatch } = appContext
	const { shippingAddress, billingAddress, hasOrderAnnotation, orderAnnotation } = checkoutContext

	const setBillingAddress: SetCartBillingAddress = {
		address: getCommerceToolsGraphQLAddressInputFromFormAddress(billingAddress),
	}

	const setShippingAddress: SetCartShippingAddress = {
		address: getCommerceToolsGraphQLAddressInputFromFormAddress(
			Object.values(shippingAddress)?.length < 2 ? billingAddress : shippingAddress,
		),
	}

	const setCustomerEmail: SetCartCustomerEmail = {
		email: shippingAddress.email,
	}

	const updateActions: MyCartUpdateAction[] = [
		{ setBillingAddress },
		{ setShippingAddress },
		{ setCustomerEmail },
	]

	// Set customer address and email
	let updatedCart = await updateCart(accessToken, cart.id, cart.version, "de", updateActions)

	// Set comment
	if (hasOrderAnnotation) {
		const setCustomField: SetCartCustomField = {
			name: "comment",
			value: `"${orderAnnotation.replace(/\n/g, " ")}"`,
		}

		updatedCart = await updateCart(accessToken, cart.id, updatedCart.version, "de", [
			{ setCustomField },
		])
	}

	return dispatchUpdatedCart(dispatch, updatedCart)
}

export default setCustomerInformationToCart
