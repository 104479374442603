import { NextSeoProps } from "next-seo"

import getOpenGraphSEOProps from "./getOpenGraphSEOProps"
import getTwitterSEOProps from "./getTwitterSEOProps"
import getLanguageAlternatesMetaTags from "./getLanguageAlternatesMetaTags"
import getLocaleAndPathSegmentsFromLocalizedAsPath from "@secureo/common/utils/i18n/getLocaleAndPathSegmentsFromLocalizedAsPath"

import { SEOProps } from "../../components/SEO"
import getPageTypeSpecificSEOProps from "./pageSpecific/getPageTypeSpecificSEOProps"

const NEXT_PUBLIC_HOST =
	process.env.NEXT_PUBLIC_HOST ||
	(process.env.NEXT_PUBLIC_VERCEL_URL
		? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
		: "http://localhost:3000")

const prependHostToPath = (path: string) => `${NEXT_PUBLIC_HOST}${path}`

const getNextSEOProps = (props: SEOProps) => {
	const { asPath, noindex = false } = props

	const { countryCode } = getLocaleAndPathSegmentsFromLocalizedAsPath(asPath)

	const canonical = prependHostToPath(asPath.split("?")[0])
	const languageAlternates = getLanguageAlternatesMetaTags(canonical, asPath)

	const pageSpecificSEOProps = getPageTypeSpecificSEOProps(props, countryCode)

	const openGraphSEOProps = getOpenGraphSEOProps({ ...props, ...pageSpecificSEOProps }, canonical)
	const twitterSEOProps = getTwitterSEOProps({ ...props, ...pageSpecificSEOProps })

	const nextSEOProps: NextSeoProps = {
		canonical,
		languageAlternates,
		...openGraphSEOProps,
		...twitterSEOProps,
		...pageSpecificSEOProps,
		noindex,
	}

	return nextSEOProps
}

export default getNextSEOProps
