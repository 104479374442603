import { PaymentMethod } from "@secureo/common/typings/Payment"

export const paymentMethods: PaymentMethod[] = [
	{
		key: "prepaid",
		name: {
			de: "Vorkasse Überweisung",
		},
		description: {
			de: "Sichern Sie sich 2% Rabatt auf Ihre ausgewählten Produkte - der Rabatt wird automatisch abgezogen.",
		},
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/Vorausskasse-big_bq7zql.png",
		isSuitableForPrivate: true,
		isSuitableForCompany: true,
		isCountryRestricted: false,
	},
	{
		key: "creditCard",
		name: {
			de: "Kreditkarte",
		},
		description: { de: "Mit Visa oder MasterCard bezahlen" },
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/Creditcard-big_lihswj.png",
		isSuitableForPrivate: true,
		isSuitableForCompany: true,
		isCountryRestricted: false,
	},
	{
		key: "paypal",
		name: {
			de: "Paypal",
		},
		description: {
			de: "Für die sichere Zahlungsabwicklung werden Sie im Anschluss an unseren Zahlungsanbieter Unzer weitergeleitet.",
		},
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/Paypal-big_idzqfc.png",
		isSuitableForPrivate: true,
		isSuitableForCompany: true,
		isCountryRestricted: false,
	},
	{
		key: "eps",
		name: {
			de: "EPS",
		},
		description: {
			de: "Für die sichere Zahlungsabwicklung werden Sie im Anschluss an unseren Zahlungsanbieter Unzer weitergeleitet.",
		},
		logoUrl:
			"https://upload.wikimedia.org/wikipedia/commons/c/c5/Eps-%C3%9Cberweisung_Logo.svg",
		isSuitableForPrivate: true,
		isSuitableForCompany: true,
		isCountryRestricted: true,
		includedCountryCodes: ["AT"],
	},
	{
		key: "pay_now",
		provider: "klarna",
		name: {
			de: "Sofortüberweisung",
		},
		description: { de: "" },
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/klarna_xn18rb.png",
		isSuitableForPrivate: true,
		isSuitableForCompany: true,
		isCountryRestricted: true,
		includedCountryCodes: ["AT", "DE"],
	},
	{
		key: "direct_bank_transfer",
		provider: "klarna",
		name: {
			de: "Sofortüberweisung",
		},
		description: { de: "" },
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/klarna_xn18rb.png",
		isSuitableForPrivate: true,
		isSuitableForCompany: true,
		isCountryRestricted: true,
		includedCountryCodes: ["AT", "DE", "CH"],
	},
	{
		key: "pay_later",
		provider: "klarna",
		name: {
			de: "Rechnungskauf",
		},
		description: { de: "" },
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/klarna_xn18rb.png",
		isSuitableForPrivate: true,
		isSuitableForCompany: true,
		isCountryRestricted: true,
		includedCountryCodes: ["AT", "DE"],
	},
	{
		key: "pay_over_time",
		provider: "klarna",
		name: {
			de: "Ratenkauf",
		},
		description: { de: "" },
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/klarna_xn18rb.png",
		isSuitableForPrivate: true,
		isSuitableForCompany: true,
		isCountryRestricted: true,
		includedCountryCodes: ["AT", "DE"],
	},
	// Rechnungskauf
	{
		key: "invoice",
		name: {
			de: "Rechnungskauf (Firmen)",
		},
		description: {
			de: "Rechnungskauf nur für Unternehmen und Institutionen (keine Privatpersonen). Bitte geben Sie Ihre gültige Umsatzsteuer-ID im Feld UID an.",
		},
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/Vorausskasse-big_bq7zql.png",
		isSuitableForPrivate: false,
		isSuitableForCompany: true,
		isCountryRestricted: false,
	},
]
