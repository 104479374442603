import isEmail from "./isEmail"
import isValidVatId from "./isValidVatId"

import {
	FormAddress,
	AddressValidationErrors,
	AddressValidationWarnings,
} from "@secureo/common/typings/FormAddress"

const validateFormAddress = (formAddress: FormAddress) => {
	const {
		isCompany,
		firstName,
		lastName,
		streetName,
		streetNumber,
		postalCode,
		city,
		country,
		email,
		phone,
		company,
		vatId,
		// additionalAddressInfo,
	} = formAddress

	const errors: AddressValidationErrors = {}
	const warnings: AddressValidationWarnings = {}

	// Name
	if (!firstName) errors.firstName = "required"
	if (!lastName) errors.lastName = "required"

	// Address
	if (!streetName) errors.streetName = "required"
	if (!streetNumber) errors.streetNumber = "required"
	if (!postalCode) errors.postalCode = "required"
	if (!city) errors.city = "required"
	if (!country) errors.country = "required"

	// Contact
	if (!isEmail(email)) errors.email = "emailIncorrect"
	if (!email) errors.email = "required"
	if (!phone) errors.phone = "required"

	// Company
	if (isCompany && !company) errors.company = "required"
	// VatId - Warn only
	if (vatId.length > 0 && !isValidVatId(vatId, country)) warnings.vatId = "vatIdIncorrect"

	// AdditionalAddressInfo
	// if (!additionalAddressInfo) errors.additionalAddressInfo = "required"

	return {
		isValid: Object.keys(errors).length === 0,
		hasWarnings: Object.keys(warnings).length > 0,
		errors,
		warnings,
	}
}

export default validateFormAddress
