import React, { useContext } from "react"
import { Box, List, ListItem, ListItemText, IconButton, Badge, ListItemButton } from "@mui/material"
import { makeStyles } from "makeStyles"
import Button from "components/Buttons/Button"
import Link from "components/i18n/Link"
import Price from "components/Price"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import mergeConfiguredLineItems from "@secureo/common/utils/commerceTools/mergeConfiguredLineItems"

import Icon from "@mdi/react"
import { mdiCartOutline } from "@mdi/js"

const useStyles = makeStyles()({
	cartDropdown: {
		position: "relative",
		display: "inline-block",
		"&:hover": {
			"& $cartDropdownContent": {
				display: "block",
			},
		},
	},
	cartDropdownContent: {
		display: "none",
		position: "absolute",
		backgroundColor: "#f9f9f9",
		width: "270px",
		right: "0px",
		boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
		border: "1px solid whitesmoke",
		borderRadius: "9px",
		zIndex: 1,
	},
})

const CartPopover = ({ actualNumberOfLineItems }: Props) => {
	const { classes } = useStyles()
	const { cart } = useContext(AppContext)

	const { lockingSystems = [], lineItems = [] } = cart ?? {}
	const mergedConfiguredLineItems = mergeConfiguredLineItems(lineItems)

	const totalGrossCents = cart?.taxedPrice?.totalGross?.centAmount ?? 0

	return (
		<>
			<div className={classes.cartDropdown}>
				<Link href="/cart">
					<IconButton
						edge="end"
						aria-label="cart"
						aria-haspopup="true"
						color="inherit"
						size="large"
					>
						<Badge
							badgeContent={actualNumberOfLineItems ? actualNumberOfLineItems : null}
							color="primary"
						>
							<Icon path={mdiCartOutline} size={1.1} color="#505e5f" />
						</Badge>
					</IconButton>
				</Link>
				<div className={classes.cartDropdownContent}>
					<Box mt={2} ml={2}>
						<strong>Warenkorb</strong>
					</Box>
					<Box mb={2}>
						<List component="nav" aria-label="help-links">
							{lockingSystems.map((lockingSystem) => {
								const {
									name,
									quantity,
									taxedPrice: {
										totalGross: { centAmount },
									},
								} = lockingSystem
								return (
									<ListItemButton
										key={lockingSystem.id}
										style={{ color: "#263739" }}
									>
										<ListItemText
											style={{ maxWidth: "75%" }}
											primary={
												<div>
													{name ? (
														<div>
															{quantity}x {name} Schließanlage
														</div>
													) : (
														<div>{quantity}x Schließanlage</div>
													)}
												</div>
											}
										/>
										<ListItemText
											primary={<Price priceInEuroCents={centAmount} />}
											style={{ textAlign: "right" }}
										/>
									</ListItemButton>
								)
							})}
							{mergedConfiguredLineItems.map((lineItem) => {
								const {
									id,
									quantity,
									taxedPrice: {
										totalGross: { centAmount: lineItemTotalGrossCents },
									},
									//configurations = [],
								} = lineItem

								const name = lineItem.variant?.variantName ?? lineItem.name

								return (
									<ListItem key={id} style={{ color: "#263739" }} button>
										<ListItemText
											style={{ maxWidth: "75%" }}
											primary={`${quantity}x ${name}`}
										/>
										<ListItemText
											primary={
												<Price priceInEuroCents={lineItemTotalGrossCents} />
											}
											style={{ textAlign: "right" }}
										/>
									</ListItem>
								)
							})}
						</List>
						{actualNumberOfLineItems !== 0 && (
							<Box
								mb={3}
								px={2}
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									fontSize: 14,
								}}
							>
								<div>
									<strong>Gesamt</strong>
								</div>
								<div>
									<strong>
										<Price priceInEuroCents={totalGrossCents} />
									</strong>
								</div>
							</Box>
						)}
						{actualNumberOfLineItems === 0 && (
							<Box mb={3} px={2} style={{ color: "#263739" }}>
								Ihr Warenkorb ist leer.
							</Box>
						)}
						<Box px={1} mt={1}>
							{actualNumberOfLineItems === 0 ? (
								<Link href="/c/elektronische-tuerschloesser">
									<Button color="primary" size="small">
										Jetzt einkaufen
									</Button>
								</Link>
							) : (
								<Link href="/cart">
									<Button color="primary" size="small">
										Zum Warenkorb
									</Button>
								</Link>
							)}
						</Box>
					</Box>
				</div>
			</div>
		</>
	)
}

interface Props {
	actualNumberOfLineItems: number
}

export default CartPopover
