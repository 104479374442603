export const escapeGraphQLCustomFieldStringValue = (string: string) => `"${string}"`

export const escapeGraphQLCustomFieldNumberValue = (number: number) => `${number}`

export const escapeGraphQLCustomFieldBooleanValue = (boolean: boolean) => `${boolean}`

export const escapeGraphQLCustomFieldArray = (array: any[], escapeItem = (item: any) => item) => {
	const escapedArray = `[${array.map((item) => escapeItem(item)).join(",")}]`
	return escapedArray
}

export const escapeGraphQLCustomFieldStringArrayValue = (stringArray: string[]) => {
	const escapedStringArray = escapeGraphQLCustomFieldArray(
		stringArray,
		escapeGraphQLCustomFieldStringValue,
	)

	return escapedStringArray
}

export const escapeGraphQLCustomFieldNumberArrayValue = (numberArray: number[]) => {
	const escapedNumberArray = escapeGraphQLCustomFieldArray(
		numberArray,
		escapeGraphQLCustomFieldNumberValue,
	)

	return escapedNumberArray
}
