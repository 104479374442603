import {
	pushEventToDataLayer,
	EnhancedEcommerceProductClickEvent,
	EnhancedEcommerceProductFieldObject,
	EnhancedEcommerceProductImpressionEvent,
	EnhancedEcommerceImpressionFieldObject,
	GA4ViewListEvent,
	GA4Item,
	GA4SelectItemEvent,
} from "@secureo/common/components/GoogleTagManager"

export const trackProductHitImpression = (
	name: string,
	grossPriceCents: number,
	brand: string,
	category: string,
	list: string,
	position: number,
) => {
	const grossPriceEuros = grossPriceCents / 100

	const impressionFieldObject: EnhancedEcommerceImpressionFieldObject = {
		name,
		price: grossPriceEuros,
		brand,
		category,
		list,
		position,
	}

	const event: EnhancedEcommerceProductImpressionEvent = {
		event: "productImpression",
		ecommerce: {
			currencyCode: "EUR",
			impressions: [impressionFieldObject],
		},
	}

	pushEventToDataLayer(event)
}

export const trackGA4ViewListItem = (
	name: string,
	grossPriceCents: number,
	brand: string,
	category: string,
	list: string,
	index: number,
) => {
	pushEventToDataLayer({ ecommerce: null })
	const grossPriceEuros = grossPriceCents / 100

	const item: GA4Item = {
		item_name: name,
		price: grossPriceEuros,
		item_brand: brand,
		item_category: category,
		item_list_name: list,
		index,
		quantity: 1,
	}

	const event: GA4ViewListEvent = {
		event: "view_item_list",
		ecommerce: {
			items: [item],
		},
	}

	pushEventToDataLayer(event)
}

export const trackProductHitClick = (
	name: string,
	grossPriceCents: number,
	brand: string,
	category: string,
	list: string,
	position: number,
) => {
	const grossPriceEuros = grossPriceCents / 100

	const productFieldObject: EnhancedEcommerceProductFieldObject = {
		name,
		price: grossPriceEuros,
		brand,
		category,
		position,
	}

	const event: EnhancedEcommerceProductClickEvent = {
		event: "productClick",
		ecommerce: {
			click: {
				actionField: {
					list,
				},
				products: [productFieldObject],
			},
		},
	}

	pushEventToDataLayer(event)
}

export const trackGA4SelectItem = (
	name: string,
	grossPriceCents: number,
	brand: string,
	category: string,
	list: string,
	index: number,
) => {
	pushEventToDataLayer({ ecommerce: null })

	const grossPriceEuros = grossPriceCents / 100

	const item: GA4Item = {
		item_name: name,
		price: grossPriceEuros,
		item_brand: brand,
		item_category: category,
		item_list_name: list,
		index,
	}

	const event: GA4SelectItemEvent = {
		event: "select_item",
		ecommerce: {
			items: [item],
		},
	}

	pushEventToDataLayer(event)
}
