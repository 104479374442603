import { useEffect, useState } from "react"

const useIntersectionObserver = (target: React.MutableRefObject<any>, root?: any) => {
	const [isIntersecting, setIntersecting] = useState(false)

	useEffect(() => {
		let observedElement = null

		if (root) {
			const observer = new IntersectionObserver(
				([entry]) => {
					if (entry.isIntersecting !== isIntersecting) {
						setIntersecting(entry.isIntersecting)
					}
				},
				{
					rootMargin: "0px",
					root: root.current,
				},
			)

			if (target.current) {
				observedElement = target.current
				observer.observe(observedElement)
			}

			return () => {
				observer.unobserve(observedElement)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [root, target])

	return isIntersecting
}

export default useIntersectionObserver
