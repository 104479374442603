import { NextSeoProps } from "next-seo"
import { parseUrlParts } from "parse-url-parts"

import { supportedValidLocales } from "@secureo/common/utils/i18n/supportedLocalesAndCurrencyCodes"

const pathRegex = /\/(?<locale>\w{2}-\w{2})(?<rest>.*)/

const getLanguageAlternatesMetaTags = (canonical: string, asPath: string) => {
	// Disable for paginated category pages
	const isPaginatedAsPath = asPath.includes("page=")
	if (isPaginatedAsPath) return []

	try {
		const { protocol, host, path = "" } = parseUrlParts(canonical)

		if (!pathRegex.test(path)) return []
		const { rest } = pathRegex.exec(path).groups

		const languageAlternates: NextSeoProps["languageAlternates"] = supportedValidLocales.reduce(
			(languageAlternates, locale) => {
				return [
					...languageAlternates,
					{ hrefLang: locale, href: `${protocol}://${host}/${locale}${rest}` },
				]
			},
			[{ hrefLang: "de", href: `${protocol}://${host}/de-DE${rest}` }],
		)

		return languageAlternates
	} catch (err) {
		console.error("Failed to create languageAlternates from: ", canonical)
		console.error(err)
		return []
	}
}

export default getLanguageAlternatesMetaTags
