import React, { useContext } from "react"
import { Box, Container } from "@mui/material"
import { makeStyles } from "makeStyles"
import Price from "components/Price"
import Link from "components/i18n/Link"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import CheckoutContext from "@secureo/common/context/CheckoutContext/CheckoutContext"

import DesktopLogo from "svg/logo-digitalzylinder-shop.svg"
import MobileLogo from "svg/digishop-ring.svg"

import { blurredStyle } from "containers/CheckoutPage/OrderOverviewAndCheckout"

import { LineItem } from "@secureo/common/typings/Cart"

const useStyles = makeStyles()((theme) => ({
	logo: {
		maxHeight: 60,
		minHeight: "100%",
		maxWidth: "190px",
		height: "60px",
		objectFit: "contain",
	},
	logoMobile: {
		display: "none",
		[theme.breakpoints.down("lg")]: {
			display: "block",
		},
	},
	logoDesktop: {
		display: "block",
		[theme.breakpoints.down("lg")]: {
			display: "none",
		},
	},
	"@media only screen and (max-width: 960px)": {
		logo: {
			maxHeight: 50,
			minHeight: "100%",
			maxWidht: "90px",
			height: "50px",
			objectFit: "contain",
		},
	},
}))

const getNumberOfLineItems = (lineItems: LineItem[]) => {
	const lineItemsWithoutConfigurations = lineItems.filter(
		({ isConfiguration }) => !isConfiguration,
	)
	const numberOfLineItems = lineItemsWithoutConfigurations.reduce(
		(numberOfLineItems, lineItem) => numberOfLineItems + lineItem.quantity,
		0,
	)

	return numberOfLineItems
}

const CheckoutHeader = () => {
	const { classes } = useStyles()
	const { cart } = useContext(AppContext)
	const { isLoading } = useContext(CheckoutContext)

	const priceStyle = isLoading ? blurredStyle : {}

	const numberOfLockingSystems = cart?.lockingSystems?.length
	const numberOfLineItems = getNumberOfLineItems(cart?.lineItems ?? [])

	return (
		<Box py={1}>
			<Container
				style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
			>
				<div className={classes.logoDesktop}>
					<Link href="/">
						<DesktopLogo className={classes.logo} />
					</Link>
				</div>
				<div className={classes.logoMobile}>
					<Link href="/">
						<MobileLogo
							style={{
								height: 40,
								width: 40,
								marginTop: 4,
								marginLeft: 4,
							}}
						/>
					</Link>
				</div>
				<div>
					<strong>
						{numberOfLineItems + numberOfLockingSystems} Artikel |{" "}
						<Price
							priceInEuroCents={cart?.taxedPrice?.totalGross?.centAmount}
							style={priceStyle}
						/>
					</strong>
				</div>
			</Container>
		</Box>
	)
}

export default CheckoutHeader
