import React from "react"
import { Box, List, ListItemText, ListItemButton } from "@mui/material"
import { makeStyles } from "makeStyles"
import Link from "components/i18n/Link"

import Icon from "@mdi/react"
import { mdiChevronDown } from "@mdi/js"

const links = [
	{
		title: "Kontakt",
		href: "/help/contact",
	},
	{
		title: "Warum mit uns?",
		href: "/help/why-choose-us",
	},
	{
		title: "Hersteller",
		href: "/brands",
	},
	{
		title: "Versand & Zahlung",
		href: "/help/shipping-and-payment",
	},
	{
		title: "Zylinder messen",
		href: "/help/how-to-measure-cylinders",
	},
	{
		title: "Gewerbekunden",
		href: "/help/business-customers",
	},
	{
		title: "Impressum & Datenschutz",
		href: "/help/imprint",
	},
	{
		title: "AGB",
		href: "/help/terms-and-conditions",
	},
	{
		title: "Widerrufsbelehrung",
		href: "/help/cancellation-policy",
	},
]

const useStyles = makeStyles()({
	dropbtn: {
		backgroundColor: "transparent",
		color: "white",
		padding: "10px",
		paddingLeft: "12px",
		paddingRight: "12px",
		fontSize: "16px",
		height: "40px",
		border: "none",
		cursor: "pointer",
		outline: "none",
		fontFamily: "inherit",
		display: "flex",
		alignItems: "center",
	},
	dropdown: {
		position: "relative",
		display: "inline-block",
		"&:hover": {
			"& $dropdownContent": {
				display: "block",
			},
			"& $dropbtn": {
				transition: "0.3s",
				backgroundColor: "#4691ce",
			},
		},
	},
	dropdownContent: {
		display: "none",
		position: "absolute",
		backgroundColor: "#f9f9f9",
		minWidth: "220px",
		boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
		zIndex: 1,
	},
})

const HelpPopoverDesktop = () => {
	const { classes } = useStyles()

	return (
		<>
			<div className={classes.dropdown}>
				<Link href="/help">
					<button className={classes.dropbtn}>
						Hilfe
						{/* <Icon path={mdiChevronDown} size={1} /> */}
					</button>
				</Link>
				{/* <div className={classes.dropdownContent}>
					<Box my={1}>
						<List component="nav" aria-label="help-links">
							{links.map((link) => {
								const { title, href } = link
								return (
									<Link href={href} key={title}>
										<ListItemButton style={{ color: "black" }} dense={true}>
											<ListItemText primary={title} />
										</ListItemButton>
									</Link>
								)
							})}
						</List>
					</Box>
				</div> */}
			</div>
		</>
	)
}

export default HelpPopoverDesktop
