import NextLink, { LinkProps } from "next/link"
import { useRouter } from "next/router"

const Link = ({ href, children, style, ...props }: Props) => {
	const router = useRouter()
	const locale = (router?.query?.locale as string) ?? "de-DE"

	const localizedHref = `/${locale}${href}`

	return (
		<NextLink {...props} href={localizedHref} style={{ textDecoration: "inherit", ...style }}>
			{children}
		</NextLink>
	)
}

interface Props extends LinkProps {
	href: string
	children: React.ReactNode
	style?: React.CSSProperties
	target?: string
	onClick?: () => void
	className?: string
}

export default Link
