import React from "react"
import { Box, ListItem, List, ListItemText, Divider, Paper } from "@mui/material"

import Price from "components/Price"

import mergeConfiguredLineItems from "@secureo/common/utils/commerceTools/mergeConfiguredLineItems"

import { LineItem, CustomLineItem, CartLockingSystem } from "@secureo/common/typings/Cart"

const getTotalLineItemDiscountsCents = (
	lineItems: LineItem[],
	lockingSystems: CartLockingSystem[],
	discountCustomLineItems: CustomLineItem[],
) => {
	const totalLineItemDiscountsCents = lineItems.reduce(
		(totalLineItemDiscountsCents, { totalDiscountGrossCents }) => {
			return totalLineItemDiscountsCents + totalDiscountGrossCents
		},
		0,
	)

	const totalLockingSystemLineItemDiscountCents = lockingSystems.reduce(
		(totalLockingSystemLineItemDiscountCents, lockingSystem) => {
			return (
				totalLockingSystemLineItemDiscountCents + lockingSystem.totalDiscountGrossCents ?? 0
			)
		},
		0,
	)

	const totalDiscountCustomLineItemsCents = discountCustomLineItems.reduce(
		(totalDiscountCustomLineItemsCents, discountCustomLineItem) => {
			return (
				totalDiscountCustomLineItemsCents +
				discountCustomLineItem.taxedPrice.totalGross.centAmount * -1
			)
		},
		0,
	)

	return (
		totalLineItemDiscountsCents +
		totalLockingSystemLineItemDiscountCents +
		totalDiscountCustomLineItemsCents
	)
}

const getTotalShippingCosts = (customLineItems: CustomLineItem[]) => {
	const totalShippingCostsEuroCents = customLineItems
		.filter(({ name, slug }) => name.includes("shipping") || slug.includes("delivery"))
		.reduce(
			(
				totalShippingCostsEuroCents,
				{
					taxedPrice: {
						totalGross: { centAmount: totalCentAmountGross },
					},
				},
			) => {
				return totalShippingCostsEuroCents + totalCentAmountGross
			},
			0,
		)

	return totalShippingCostsEuroCents
}

const OrderOverview = ({
	lineItems,
	customLineItems,
	totalGrossCents,
	priceStyle = {},
	lockingSystems,
}: // isOrderSuccessPage = false,
Props) => {
	const mergedConfiguredLineItems = mergeConfiguredLineItems(lineItems)
	const totalShippingCostsEuroCents = getTotalShippingCosts(customLineItems)

	const discountCustomLineItems = customLineItems.filter((customLineItem) =>
		customLineItem.slug.startsWith("discount-"),
	)
	const totalLineItemDiscountsCents = getTotalLineItemDiscountsCents(
		lineItems,
		lockingSystems,
		discountCustomLineItems,
	)

	return (
		<>
			<Box mb={2} className="h2">
				Bestellzusammenfassung
			</Box>
			<Paper style={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.10)" }}>
				<div style={{ background: "#EBF1F7" }}>
					<List component="nav" aria-label="secondary mailbox folders">
						{lockingSystems.map((lockingSystem) => {
							const { name, quantity, totalUnDiscountedPriceGrossCents } =
								lockingSystem

							return (
								<ListItem key={lockingSystem.id}>
									<ListItemText
										style={{ maxWidth: "75%" }}
										primary={
											<div>
												{name ? (
													<div>
														{quantity}x {name} Schließanlage
													</div>
												) : (
													<div>{quantity}x Schließanlage</div>
												)}
											</div>
										}
									/>
									<ListItemText
										primary={
											<Price
												priceInEuroCents={totalUnDiscountedPriceGrossCents}
												style={priceStyle}
											/>
										}
										style={{ textAlign: "right" }}
									/>
								</ListItem>
							)
						})}
						{mergedConfiguredLineItems.map((lineItem) => {
							const {
								id,
								quantity,
								totalUnDiscountedPriceGrossCents,
								configurations = [],
							} = lineItem

							const name = lineItem.variant?.variantName ?? lineItem.name

							const configurationsTotalUnDiscountedPriceGrossCents =
								configurations.reduce(
									(
										configurationsTotalUnDiscountedPriceGrossCents,
										configuration,
									) => {
										return (
											configurationsTotalUnDiscountedPriceGrossCents +
											configuration.totalUnDiscountedPriceGrossCents
										)
									},
									0,
								)

							return (
								<ListItem key={id}>
									<ListItemText
										style={{ maxWidth: "75%" }}
										primary={`${quantity}x ${name}`}
									/>
									<ListItemText
										primary={
											<Price
												priceInEuroCents={
													totalUnDiscountedPriceGrossCents +
													configurationsTotalUnDiscountedPriceGrossCents
												}
												style={priceStyle}
											/>
										}
										style={{ textAlign: "right" }}
									/>
								</ListItem>
							)
						})}
						<Box px={2} py={1}>
							<Divider />
						</Box>

						{totalLineItemDiscountsCents > 0 && (
							<ListItem style={{ display: "flex", justifyContent: "space-between" }}>
								<ListItemText primary={<div>Rabatte</div>} />
								<ListItemText
									primary={
										<div style={{ textAlign: "right" }}>
											<Price
												priceInEuroCents={-totalLineItemDiscountsCents}
												style={priceStyle}
											/>
										</div>
									}
								/>
							</ListItem>
						)}

						{totalShippingCostsEuroCents > 0 && (
							<ListItem style={{ display: "flex", justifyContent: "space-between" }}>
								<ListItemText primary={<div>Versandkosten</div>} />
								<ListItemText
									primary={
										<div style={{ textAlign: "right" }}>
											<Price
												priceInEuroCents={totalShippingCostsEuroCents}
												style={priceStyle}
											/>
										</div>
									}
								/>
							</ListItem>
						)}

						<ListItem>
							<ListItemText
								primary={
									<div>
										<strong>Gesamt</strong>
									</div>
								}
							/>
							<ListItemText
								primary={
									<div>
										<strong>
											<Price
												priceInEuroCents={totalGrossCents}
												style={priceStyle}
											/>
										</strong>
									</div>
								}
								style={{ textAlign: "right" }}
							/>
						</ListItem>
					</List>
				</div>
				<Box px={2} py={1}>
					<p style={{ color: "#707070", fontSize: 10 }}>
						*Die oben genannten Preise verstehen sich inkl. Mehrwertsteuer
					</p>
				</Box>
			</Paper>
		</>
	)
}
interface Props {
	lockingSystems: CartLockingSystem[]
	lineItems: LineItem[]
	customLineItems: CustomLineItem[]
	totalGrossCents: number
	priceStyle?: React.CSSProperties
	isOrderSuccessPage?: boolean
}

export default OrderOverview
