import normalizeLocale from "@secureo/common/utils/normalizeLocale"

import { CountryCode } from "@secureo/common/typings/CountryCode"

export const fallback = {
	locale: null,
	language: null,
	countryCode: null,
	topLevelPath: null,
	slug: null,
}

const getLocaleAndPathSegmentsFromLocalizedAsPath = (
	localizedAsPath: string,
): {
	locale?: string
	language?: string
	countryCode?: CountryCode
	topLevelPath?: string
	slug?: string
} => {
	if (localizedAsPath === "/") return fallback

	const [, rawLocale, topLevelPath, slug] = localizedAsPath.split("/")
	const { locale, language, countryCode } = normalizeLocale(rawLocale)

	return {
		locale,
		language,
		countryCode,
		topLevelPath,
		slug,
	}
}

export default getLocaleAndPathSegmentsFromLocalizedAsPath
