import React from "react"
import { NextSeo, DefaultSeo as DefaultNextSeo, NextSeoProps } from "next-seo"

import getNextSEOProps from "../utils/seo/getNextSEOProps"

const SEO = (props: SEOProps) => {
	const nextSeoProps = getNextSEOProps(props)

	return <NextSeo {...nextSeoProps} />
}

export interface SEOProps extends DefaultSEOProps {
	asPath: string
	appendLocalizedShopNameWithPipe?: boolean
	pageType?: string
	productName?: string
	categoryName?: string
}

export default SEO

export const DefaultSEO = (props: DefaultSEOProps) => {
	const nextSeoProps = getNextSEOProps(props)

	return <DefaultNextSeo {...nextSeoProps} />
}

interface DefaultSEOProps extends NextSeoProps {
	asPath: string
}
