import axios from "axios"

import {
	KlarnaPaymentsSDK,
	KlarnaCreateSessionResponse,
	KlarnaCreateOrderResponse,
} from "@secureo/common/typings/KlarnaPaymentsSDK"
import { KlarnaSessionCreation, KlarnaSessionUpdate } from "@secureo/common/typings/Klarna"
import { PaymentMethodKey } from "@secureo/common/typings/Payment"

export const klarnaWidgetContainersIds = {
	pay_now: "klarna_pay_now_container",
	pay_later: "klarna_pay_later_container",
	pay_over_time: "klarna_pay_over_time_container",
	direct_bank_transfer: "klarna_direct_bank_transfer_container",
}

const createKlarnaSessionApi = "/api/klarna/createKlarnaSession"
const createKlarnaOrderApi = "/api/klarna/createKlarnaOrder"

export const createKlarnaSession = async (klarnaSession: KlarnaSessionCreation) => {
	const { data } = (await axios.post(createKlarnaSessionApi, klarnaSession)) as {
		data: KlarnaCreateSessionResponse
	}

	const { client_token, payment_method_categories, session_id } = data

	return {
		client_token,
		payment_method_categories,
		session_id,
	}
}

export const initialize = async (klarnaSession: KlarnaSessionCreation) => {
	const Klarna: KlarnaPaymentsSDK = window["Klarna"]
	const { client_token, payment_method_categories, session_id } =
		await createKlarnaSession(klarnaSession)

	// TODO: Check if window.Klarna is defined aka script is loaded
	Klarna.Payments.init({
		client_token,
	})

	return {
		client_token,
		payment_method_categories,
		session_id,
	}
}

export const load = async (
	sessionUpdate: KlarnaSessionUpdate,
	paymentMethodCategoryIdentifier: string,
): Promise<{ show_form: boolean }> => {
	const Klarna: KlarnaPaymentsSDK = window["Klarna"]

	const container = klarnaWidgetContainersIds[paymentMethodCategoryIdentifier]

	return new Promise((resolve, reject) => {
		Klarna.Payments.load(
			{
				container: `#${container}`,
				payment_method_category: paymentMethodCategoryIdentifier,
			},
			sessionUpdate,
			({ error, show_form }) => {
				if (error) reject(error)
				resolve({ show_form })
			},
		)
	})
}

export const authorize = async (
	sessionUpdate: KlarnaSessionUpdate,
	paymentMethodCategoryIdentifier: string,
): Promise<{
	authorization_token: string
	show_form: boolean
	approved: boolean
	finalize_required: boolean
}> => {
	const Klarna: KlarnaPaymentsSDK = window["Klarna"]

	return new Promise((resolve, reject) => {
		try {
			Klarna.Payments.authorize(
				{
					payment_method_category: paymentMethodCategoryIdentifier,
				},
				sessionUpdate,
				(res) => {
					resolve(res)
				},
			)
		} catch (err) {
			reject(err)
		}
	})
}

export const createKlarnaOrder = async (
	session_id: string,
	cartId: string,
	selectedPaymentMethod: PaymentMethodKey,
) => {
	const { data } = (await axios.get(
		`${createKlarnaOrderApi}?session_id=${session_id}&commerceToolsCartId=${cartId}&selectedPaymentMethod=${selectedPaymentMethod}`,
	)) as {
		data: KlarnaCreateOrderResponse
	}

	const { order_id, redirect_url, fraud_status, authorized_payment_method } = data

	return { order_id, redirect_url, fraud_status, authorized_payment_method }
}
