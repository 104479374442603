import React, { useRef } from "react"
import Router from "next/router"
import {
	Grid,
	Box,
	Button,
	ButtonGroup,
	ClickAwayListener,
	Menu,
	MenuItem,
	MenuList,
} from "@mui/material"
import Icon from "@mdi/react"
import { mdiChevronDown } from "@mdi/js"

import CountryFlag from "./CountryFlag"

import useToggle from "@secureo/common/hooks/useToggle"
import useTranslation from "@secureo/common/hooks/useTranslation"

import { shopCountryCodes } from "@secureo/common/utils/i18n/supportedLocalesAndCurrencyCodes"

import { CountryCode } from "@secureo/common/typings/CountryCode"

const getLocalizedUrl = (url: string, locale: string) => {
	const localizedUrl = url.replace(/\/\w{2}-\w{2}/, `/${locale}`)

	return localizedUrl
}

const SwitchCountry = ({ countryCode }: Props) => {
	const [isOpen, toggleIsOpen] = useToggle()
	const anchorRef = useRef<HTMLDivElement>(null)

	const { t } = useTranslation("countries")

	const navigateToLocalizedUrl = (locale: string) => {
		const { route, asPath } = Router.router

		if (asPath.includes("/cart") || asPath.includes("/checkout")) {
			const localizedUrl = getLocalizedUrl(window.location.href, locale)
			window.location.href = localizedUrl
		}

		const localizedUrl = getLocalizedUrl(asPath, locale)

		Router.push(route, localizedUrl)
	}

	const switchToCountry = (selectedCountryCode: CountryCode) => {
		if (selectedCountryCode !== countryCode) {
			navigateToLocalizedUrl(`de-${selectedCountryCode}`)
			toggleIsOpen()
		}
	}

	return (
		<Grid container direction="column" alignItems="center">
			<Grid item xs={12}>
				<ButtonGroup
					variant="contained"
					color="primary"
					ref={anchorRef}
					aria-label="split button"
					style={{ boxShadow: "none" }}
				>
					<Button
						style={{
							height: 40,
							borderRadius: 0,
							boxShadow: "none",
							background: "rgba(255, 255, 255, 0.15)",
							border: "none",
						}}
						onClick={toggleIsOpen}
					>
						<Box
							style={{
								width: "25px",
								height: "100%",
								paddingTop: 7,
							}}
						>
							<CountryFlag countryCode={countryCode} />
						</Box>
					</Button>
					<Button
						color="primary"
						size="small"
						aria-controls={isOpen ? "switch-country-menu" : undefined}
						aria-expanded={isOpen ? "true" : undefined}
						aria-label="select country"
						aria-haspopup="menu"
						onClick={toggleIsOpen}
						style={{
							height: 40,
							borderRadius: 0,
							boxShadow: "none",
							background: "rgba(255, 255, 255, 0.15)",
						}}
					>
						<Icon path={mdiChevronDown} size={1} />
					</Button>
				</ButtonGroup>
				<Menu open={isOpen} id="switch-country" anchorEl={anchorRef.current}>
					<ClickAwayListener onClickAway={toggleIsOpen}>
						<MenuList>
							{shopCountryCodes.map((shopCountryCode) => {
								return (
									<MenuItem
										key={shopCountryCode}
										selected={shopCountryCode === countryCode}
										onClick={() => switchToCountry(shopCountryCode)}
										dense={true}
									>
										<Box
											px={1}
											py={1}
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Box
												style={{
													width: "20px",
													height: "100%",
													paddingTop: 4,
													marginRight: 5,
												}}
											>
												<CountryFlag countryCode={shopCountryCode} />
											</Box>
											{t(shopCountryCode)}
										</Box>
									</MenuItem>
								)
							})}
						</MenuList>
					</ClickAwayListener>
				</Menu>
			</Grid>
		</Grid>
	)
}

interface Props {
	countryCode: CountryCode
}

export default SwitchCountry
