import React from "react"

import { FullPageSpinner } from "../Spinner"

import useAuthentication from "@secureo/common/hooks/useAuthentication"

const AuthenticatedPage = ({ children }: Props) => {
	const showPage = useAuthentication()

	if (!showPage) return <FullPageSpinner />

	return <>{children}</>
}

interface Props {
	children: React.ReactNode
}

export default AuthenticatedPage
