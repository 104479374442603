import React from "react"
import {
	Box,
	Container,
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	List,
	ListItem,
	Hidden,
} from "@mui/material"
import { makeStyles } from "makeStyles"
import Icon from "@mdi/react"
import {
	mdiHeadset,
	mdiChevronDown,
	mdiFacebook,
	mdiInstagram,
	mdiYoutube,
	mdiEmailOutline,
} from "@mdi/js"

import Link from "components/i18n/Link"
import Button from "../Buttons/Button"
import { getLocalizedPhoneNumber, Phone, Email } from "components/i18n/Contact"
import { openingHours, socialMediaPages } from "config/contact"

import Klarna from "svg/paymentMethods/klarna.svg"
import Mastercard from "svg/paymentMethods/mastercard.svg"
import Paypal from "svg/paymentMethods/paypal.svg"
import Visa from "svg/paymentMethods/visa.svg"
import Vorkasse from "svg/paymentMethods/vorkasse.svg"

import LionsHome from "svg/partners/lions-home.svg"
// import Idealo from "svg/partners/idealo.svg"

import { CountryCode } from "@secureo/common/typings/CountryCode"
import removeWhiteSpace from "@secureo/common/utils/removeWhiteSpace"

const useStyles = makeStyles()({
	footer: {
		background: "#273739",
		color: "white",
		paddingTop: 30,
		paddingBottom: 30,
	},
	headline: {
		color: "white",
		textTransform: "uppercase",
		fontWeight: 800,
		marginBottom: 10,
		padding: 0,
	},
	button: {
		border: "1px solid white",
		color: "white",
		textTransform: "inherit",
		paddingLeft: 15,
		paddingRight: 15,
	},
	Accordion: {
		background: "rgba(247, 249, 250, 0.15)",
		color: "white",
		marginBottom: 10,
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
	},
	listLink: {
		paddingLeft: 0,
		color: "white",
		transition: "0.3s",
		cursor: "pointer",
		width: "100%",
		padding: 5,
		"&:hover": {
			paddingLeft: 5,
		},
	},
})

const quickLinks = [
	// {
	// 	name: "Systemberater",
	// 	url: "/locking-system-advisor",
	// },
	{
		name: "Schließanlagenplaner",
		url: "/locking-system",
	},
	{
		name: "Hersteller Übersicht",
		url: "/brands",
	},
	{
		name: "Gewerbekunden",
		url: "/help/business-customers",
	},
	{
		name: "Zylinder messen",
		url: "/help/how-to-measure-cylinders",
	},
]

const infoLinks = [
	{
		name: "Kontaktformular",
		url: "/help/contact",
	},
	{
		name: "Warum mit uns?",
		url: "/help/why-choose-us",
	},
	{
		name: "Versand und Zahlung",
		url: "/help/shipping-and-payment",
	},
	{
		name: "Impressum",
		url: "/help/imprint",
	},
	{
		name: "Widerrufsbelehrung",
		url: "/help/cancellation-policy",
	},
	{
		name: "AGB",
		url: "/help/terms-and-conditions",
	},
]

const PaymentMethods = () => {
	return (
		<Link href="/help/shipping-and-payment">
			<div
				style={{
					display: "flex",
					flexFlow: "wrap",
					marginTop: 10,
				}}
			>
				<div style={{ marginRight: 5 }}>
					<Klarna style={{ marginBottom: -10 }} />
				</div>
				<div style={{ marginRight: 5 }}>
					<Mastercard style={{ marginBottom: -10 }} />
				</div>
				<div style={{ marginRight: 5 }}>
					<Paypal style={{ marginBottom: -10 }} />
				</div>
				<div style={{ marginRight: 5 }}>
					<Visa style={{ marginBottom: -10 }} />
				</div>
				<div style={{ marginRight: 5 }}>
					<Vorkasse style={{ marginBottom: -10 }} />
				</div>
			</div>
		</Link>
	)
}

const DesktopFooter = ({ countryCode }: Props) => {
	const { classes } = useStyles()

	return (
		<div className={classes.footer}>
			<Container>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.headline}>
							<div className="h5">Kontakt</div>
						</div>
						<div>
							Haben Sie Fragen? Rufen Sie uns an! <br />
							{openingHours}
						</div>
						<Box mt={2} style={{ display: "flex", alignItems: "center" }}>
							<Icon path={mdiEmailOutline} size={1} color="white" />
							<Box ml={1}>
								<Email />
							</Box>
						</Box>
						{/* Phone */}
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginTop: 20,
								marginBottom: 20,
							}}
						>
							<Icon path={mdiHeadset} size={2} color="white" />
							<div style={{ marginLeft: 10 }}>
								<div>
									<strong>
										<Phone countryCode={countryCode} />
									</strong>
								</div>
							</div>
						</div>
						{/* Desktop Social Media */}
						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<div>
								<a
									href={socialMediaPages.facebook}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Icon path={mdiFacebook} size={1} color="white" />
								</a>
							</div>
							<div style={{ marginLeft: 10 }}>
								<a
									href={socialMediaPages.instagram}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Icon path={mdiInstagram} size={1} color="white" />
								</a>
							</div>
							<div style={{ marginLeft: 10 }}>
								<a
									href={socialMediaPages.youtube}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Icon path={mdiYoutube} size={1} color="white" />
								</a>
							</div>
						</div>
					</Grid>
					{/* Desktop Quick Links */}
					<Grid item xs={12} sm={6} md={3}>
						<div className={classes.headline}>
							<div className="h5">Quick Links</div>
						</div>
						{quickLinks.map((quickLink) => {
							const { name, url } = quickLink
							return (
								<Link href={url} key={name}>
									<div className={classes.listLink}>{name}</div>
								</Link>
							)
						})}
					</Grid>
					{/* Desktop Info Links */}
					<Grid item xs={12} sm={6} md={3}>
						<div className={classes.headline}>
							<div className="h5">Info</div>
						</div>
						{infoLinks.map((infoLink) => {
							const { name, url } = infoLink
							return (
								<Link href={url} key={name}>
									<div className={classes.listLink}>{name}</div>
								</Link>
							)
						})}
					</Grid>
					<Grid item xs={12} sm={6} md={2}>
						<div className="h5" style={{ textTransform: "uppercase" }}>
							Unsere Partner
						</div>
						<div style={{ display: "flex", alignItems: "center", margin: "15px 0px" }}>
							<a
								href="https://www.lionshome.de/"
								target="_blank"
								rel="nooepener noreferrer"
							>
								<LionsHome style={{ height: 30, marginRight: 10 }} />
							</a>
							{/* <a
								href="https://www.idealo.de/preisvergleich/Shop/302804.html"
								target="_blank"
								rel="nooepener noreferrer"
							>
								<Idealo style={{ height: 30 }} />
							</a> */}
						</div>
						<div className="h5" style={{ textTransform: "uppercase" }}>
							Zahlungsarten
						</div>
						<PaymentMethods />
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

const MobileFooter = ({ countryCode }: Props) => {
	const { classes } = useStyles()

	const phoneNumber = getLocalizedPhoneNumber(countryCode)

	return (
		<div style={{ background: "#263739", color: "white", paddingTop: 30, paddingBottom: 30 }}>
			<div style={{ textAlign: "center" }}>
				<div className="h5" style={{ textTransform: "uppercase" }}>
					Kontakt
				</div>
				<div style={{ color: "#E3E5E6" }}>
					Haben Sie Fragen? Rufen Sie uns an! <br /> {openingHours}
				</div>
				<Box
					mt={2}
					style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
				>
					<Icon path={mdiEmailOutline} size={1} color="white" />
					<Box ml={1}>
						<Email />
					</Box>
				</Box>
				{/* CTA Buttons */}
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginTop: 15,
						marginBottom: 15,
					}}
				>
					{countryCode !== "CH" && (
						<div style={{ marginRight: 10 }}>
							<Button color="secondaryOutlined" className={classes.button}>
								<Icon path={mdiHeadset} size={1} color="#4691CE" />
								<div style={{ marginLeft: 5 }}>
									<a
										href={`tel:${removeWhiteSpace(phoneNumber)}`}
										className="link"
										data-hj-whitelist
									>
										Anrufen {countryCode}
									</a>
								</div>
							</Button>
						</div>
					)}
					<Button color="secondaryOutlined" className={classes.button}>
						<Icon path={mdiHeadset} size={1} color="#4691CE" />
						<div style={{ marginLeft: 5 }}>
							<a href="tel:+43512932791" className="link" data-hj-whitelist>
								Anrufen EU
							</a>
						</div>
					</Button>
				</div>
				{/* Social Media Icons */}
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<div>
						<a
							href={socialMediaPages.facebook}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Icon path={mdiFacebook} size={1} color="white" />
						</a>
					</div>
					<div style={{ marginLeft: 5 }}>
						<a
							href={socialMediaPages.instagram}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Icon path={mdiInstagram} size={1} color="white" />
						</a>
					</div>
					<div style={{ marginLeft: 5 }}>
						<a
							href={socialMediaPages.youtube}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Icon path={mdiYoutube} size={1} color="white" />
						</a>
					</div>
				</div>
				{/* Expansion Panels | Links */}
				<div style={{ marginTop: 15, paddingLeft: 10, paddingRight: 10 }}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={6}>
							<Accordion
								className={classes.Accordion}
								style={{
									borderTopLeftRadius: 5,
									borderTopRightRadius: 5,
									boxShadow: "none",
									background: "none",
								}}
							>
								<AccordionSummary
									expandIcon={
										<Icon path={mdiChevronDown} size={1} color="black" />
									}
									aria-controls="quicklinks"
									id="quicklinks"
									style={{
										background: "rgba(247, 249, 250, 0.25)",
										borderRadius: 10,
									}}
								>
									<div>
										<strong>Quicklinks</strong>
									</div>
								</AccordionSummary>
								<AccordionDetails
									style={{
										color: "white",
										background: "#263739",
										padding: 0,
									}}
								>
									<List
										component="nav"
										aria-label="quick links list"
										style={{ color: "white", width: "100%" }}
									>
										{quickLinks.map((quickLink) => {
											const { name, url } = quickLink
											return (
												<Link href={url} key={name}>
													<ListItem
														button
														className={classes.listLink}
														style={{
															background: "rgba(247, 249, 250, 0.1)",
															borderRadius: 9,
															padding: 12,
															paddingLeft: 12.5,
															marginBottom: 5,
														}}
													>
														{name}
													</ListItem>
												</Link>
											)
										})}
									</List>
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Accordion
								className={classes.Accordion}
								style={{
									borderTopLeftRadius: 5,
									borderTopRightRadius: 5,
									boxShadow: "none",
									background: "none",
								}}
							>
								<AccordionSummary
									expandIcon={
										<Icon path={mdiChevronDown} size={1} color="black" />
									}
									aria-controls="info"
									id="info"
									style={{
										background: "rgba(247, 249, 250, 0.25)",
										borderRadius: 10,
									}}
								>
									<div>
										<strong>Info</strong>
									</div>
								</AccordionSummary>
								<AccordionDetails
									style={{
										color: "white",
										background: "#263739",
										padding: 0,
									}}
								>
									<List
										component="nav"
										aria-label="info list"
										style={{ color: "white", width: "100%" }}
									>
										{infoLinks.map((infoLink) => {
											const { name, url } = infoLink
											return (
												<Link href={url} key={name}>
													<ListItem
														button
														className={classes.listLink}
														style={{
															background: "rgba(247, 249, 250, 0.1)",
															borderRadius: 9,
															padding: 12,
															paddingLeft: 12.5,
															marginBottom: 5,
														}}
													>
														{name}
													</ListItem>
												</Link>
											)
										})}
									</List>
								</AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
				</div>
				{/* SVG Section */}
				<div style={{ marginTop: 15 }}>
					<div className="h5" style={{ textTransform: "uppercase" }}>
						Unsere Partner
					</div>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginTop: 10,
					}}
				>
					<a href="https://www.lionshome.de/" target="_blank" rel="nooepener noreferrer">
						<LionsHome style={{ height: 30, marginRight: 10 }} />
					</a>
					{/* <a
						href="https://www.idealo.de/preisvergleich/Shop/302804.html"
						target="_blank"
						rel="nooepener noreferrer"
					>
						<Idealo style={{ height: 30 }} />
					</a> */}
				</div>
				<div style={{ marginTop: 5 }}>
					<div className="h5" style={{ textTransform: "uppercase" }}>
						Zahlungsmethoden
					</div>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginTop: 10,
					}}
				>
					<PaymentMethods />
				</div>
			</div>
		</div>
	)
}

const Footer = ({ countryCode }: Props) => {
	return (
		<footer>
			<Hidden mdDown>
				<DesktopFooter countryCode={countryCode} />
			</Hidden>
			<Hidden smUp>
				<MobileFooter countryCode={countryCode} />
			</Hidden>
			<div style={{ paddingTop: 10, paddingBottom: 10, background: "rgba(39, 55, 57, 0.9)" }}>
				<Container>
					<div style={{ textAlign: "center", fontSize: "0.9rem", color: "white" }}>
						© {new Date().getFullYear()} Digitalzylinder-Shop - ein Shop der Secureo
						GmbH
					</div>
				</Container>
			</div>
		</footer>
	)
}

interface Props {
	countryCode: CountryCode
}

export default Footer
