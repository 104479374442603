import React, { useContext } from "react"
import Link from "components/i18n/Link"
import Icon from "@mdi/react"
import { mdiAccountCircleOutline, mdiHeartOutline } from "@mdi/js"
import { Container, Box, AppBar, Toolbar, IconButton, Badge } from "@mui/material"
import { makeStyles } from "makeStyles"
import { InstantSearch } from "react-instantsearch-dom"

import AppContext from "@secureo/common/context/AppContext/AppContext"

import TopBar from "./TopBar"
import NavigationDesktop from "components/Navigation/NavigationDesktop"
import NavigationMobile from "../Navigation/NavigationMobile"
import SearchBox from "../Algolia/SearchBox/SearchBox"
import CartPopover from "components/Navigation/CartPopover"

import algoliaSearchClient from "@secureo/common/utils/algolia/searchClient"
import searchConfig from "config/searchConfig"
import navCategories from "config/navCategories.json"
import getLanguageAndCountryCodeFromLocale from "@secureo/common/utils/i18n/getLanguageAndCountryCodeFromLocale"
import DesktopLogo from "svg/logo-digitalzylinder-shop.svg"
import MobileLogo from "svg/digishop-ring.svg"

import { NavCategory } from "@secureo/common/utils/commerceTools/graphql/queries/fetchNavCategories"
import { LineItem } from "@secureo/common/typings/Cart"
import { ShoppingListLineItem } from "@secureo/common/typings/ShoppingList"

const useStyles = makeStyles()((theme) => ({
	logo: {
		maxHeight: 60,
		minHeight: "100%",
		maxWidth: "190px",
		objectFit: "contain",
		marginTop: -5,
	},
	// APPBAR
	grow: {
		flexGrow: 1,
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	showDesktopNavigation: {
		display: "block",
		[theme.breakpoints.down("lg")]: {
			display: "none",
		},
	},
	showMobileNavigation: {
		display: "none",
		[theme.breakpoints.down("lg")]: {
			display: "block",
		},
	},
	logoMobile: {
		display: "none",
		[theme.breakpoints.down("lg")]: {
			display: "block",
		},
	},
	logoDesktop: {
		display: "block",
		[theme.breakpoints.down("lg")]: {
			display: "none",
		},
	},
	search: {
		display: "block",
		[theme.breakpoints.down("lg")]: {
			display: "none",
		},
	},
	"@media only screen and (max-width: 960px)": {
		logo: {
			maxHeight: 50,
			minHeight: "100%",
			maxWidht: "90px",
			height: "50px",
			objectFit: "contain",
		},
	},
}))

const getNumberOfLineItems = (lineItems: (LineItem | ShoppingListLineItem)[]) => {
	const lineItemsWithoutConfigurations = lineItems.filter(
		({ isConfiguration }) => !isConfiguration,
	)
	const numberOfLineItems = lineItemsWithoutConfigurations.reduce(
		(numberOfLineItems, lineItem) => numberOfLineItems + lineItem.quantity,
		0,
	)

	return numberOfLineItems
}

const searchClient = {
	search(requests: any[]) {
		if (
			requests.every(
				({ params }) =>
					!params.query || params.query.length < searchConfig.minCharactersBeforeSearch,
			)
		) {
			return Promise.resolve({
				results: requests.map(() => ({
					hits: [],
					nbHits: 0,
					nbPages: 0,
					processingTimeMS: 0,
				})),
			})
		}

		return algoliaSearchClient.search(requests)
	},
}

const Header = ({ locale }: Props) => {
	const { classes } = useStyles()
	const { cart, shoppingList, dispatch, isLoggedIn, showHeader, isInitialized } =
		useContext(AppContext)

	const numberOfLockingSystems = cart?.lockingSystems?.length
	const numberOfLineItems = getNumberOfLineItems(cart?.lineItems ?? [])

	const actualNumberOfLineItems = numberOfLineItems + numberOfLockingSystems

	const numberOfShoppingListLineItems = getNumberOfLineItems(shoppingList?.lineItems ?? [])

	const openLoginForm = () => {
		dispatch({
			type: "OPEN_LOGIN_REGISTER_FORM_TAB",
			payload: "login",
		})
	}

	const indexName = `DZN-${locale}-products`

	const { countryCode } = getLanguageAndCountryCodeFromLocale(locale)

	return (
		<header>
			<InstantSearch indexName={indexName} searchClient={searchClient}>
				<TopBar />

				{showHeader && (
					<Box my={1}>
						<Container>
							<AppBar
								position="relative"
								style={{
									background: "none",
									boxShadow: "none",
								}}
							>
								<Toolbar
									style={{
										paddingLeft: 0,
										paddingRight: 0,
									}}
								>
									<div className={classes.logoDesktop}>
										<Link href="/">
											<DesktopLogo
												className={classes.logo}
												style={{ height: 60 }}
											/>
										</Link>
									</div>
									<div className={classes.logoMobile}>
										<Link href="/">
											<MobileLogo
												style={{
													height: 40,
													width: 40,
													marginTop: 4,
													marginLeft: 4,
												}}
											/>
										</Link>
									</div>
									<Box
										className={classes.search}
										style={{ textAlign: "center", width: "100%" }}
									>
										<SearchBox locale={locale} />
									</Box>
									<div className={classes.grow} />

									<div style={{ position: "relative" }}>
										{isLoggedIn && (
											<div
												style={{
													height: 18,
													width: 18,
													borderRadius: 18,
													background: "#5DA67A",
													position: "absolute",
													top: 3,
													right: 10,
													zIndex: 2,
												}}
											/>
										)}
										{isLoggedIn ? (
											<Link href="/account">
												<IconButton
													aria-label="help"
													color="inherit"
													size="large"
												>
													<Icon
														path={mdiAccountCircleOutline}
														size={1.1}
														color="#505e5f"
													/>
												</IconButton>
											</Link>
										) : (
											<IconButton
												aria-label="help"
												color="inherit"
												onClick={openLoginForm}
												size="large"
											>
												<Icon
													path={mdiAccountCircleOutline}
													size={1.1}
													color="#505e5f"
												/>
											</IconButton>
										)}
									</div>
									<Link href="/shopping-list">
										<IconButton
											aria-label="wunschliste"
											color="inherit"
											size="large"
										>
											<Badge
												badgeContent={numberOfShoppingListLineItems}
												color="primary"
											>
												<Icon
													path={mdiHeartOutline}
													size={1.1}
													color="#505e5f"
												/>
											</Badge>
										</IconButton>
									</Link>
									{isInitialized && (
										<CartPopover
											actualNumberOfLineItems={actualNumberOfLineItems}
										/>
									)}
								</Toolbar>
							</AppBar>
						</Container>
					</Box>
				)}

				<div className={classes.showDesktopNavigation}>
					<NavigationDesktop
						navCategories={navCategories as NavCategory[]}
						countryCode={countryCode}
					/>
				</div>

				<div className={classes.showMobileNavigation}>
					<NavigationMobile
						navCategories={navCategories as NavCategory[]}
						locale={locale}
						countryCode={countryCode}
					/>
				</div>
			</InstantSearch>
		</header>
	)
}

interface Props {
	locale: string
}

export default Header
