import {
	KlarnaSessionCreation,
	KlarnaOrderLine,
	KlarnaAddress,
	KlarnaCustomer,
	KlarnaMerchantUrls,
	KlarnaSessionLocale,
} from "@secureo/common/typings/Klarna"
import { FormAddress } from "@secureo/common/typings/FormAddress"
import { CountryCode } from "@secureo/common/typings/CountryCode"
import { Cart, LineItem, CustomLineItem } from "@secureo/common/typings/Cart"

const getKlarnaOrderTaxAmount = (klarnaOrderLines: KlarnaOrderLine[]) => {
	const order_tax_amount = klarnaOrderLines.reduce(
		(order_tax_amount, { total_tax_amount }) => order_tax_amount + total_tax_amount,
		0,
	)

	return order_tax_amount
}

const getKlarnaTotalTaxAmount = (
	total_amount: number,
	tax_rate: number,
	calculated_total_tax_amount: number,
) => {
	// Klarna payments API expects this to be +- 1 of the formula below
	const expectedTotalTaxAmount = total_amount - (total_amount * 10000) / (10000 + tax_rate)

	const difference = Math.abs(calculated_total_tax_amount - expectedTotalTaxAmount)

	if (difference < 1) return calculated_total_tax_amount

	console.info(
		`Tweaking tax amount of lineItem, calculated: ${calculated_total_tax_amount}, expected: ${expectedTotalTaxAmount} +/- 1`,
	)

	// Else return amount that Klarna expects
	return expectedTotalTaxAmount
}

const getKlarnaOrderLineFromLineItem = (lineItem: LineItem, host: string, shopLocale: string) => {
	const {
		name,
		variant: { sku, images },
		quantity,
		taxedPrice: {
			totalGross: { centAmount: totalGrossPrice },
			totalNet: { centAmount: totalNetPrice },
		},
		taxRate: { rate: taxRate },
		productSlug,
	} = lineItem

	const unit_price = totalGrossPrice / quantity
	const total_tax_amount = totalGrossPrice - totalNetPrice
	const tax_rate = taxRate * 100 ** 2

	const img = images[0]

	const productUrlPrefix =
		process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY === "TRE" ? "" : `/${shopLocale}`

	const klarnaOrderLine: KlarnaOrderLine = {
		name,
		quantity,
		reference: sku,
		tax_rate,
		total_amount: totalGrossPrice, // Includes tax and discounts
		product_url: `${host}${productUrlPrefix}/p/${productSlug}`,
		type: "physical",
		unit_price, // Minor units. Includes tax, excludes discount
		total_tax_amount: getKlarnaTotalTaxAmount(totalGrossPrice, tax_rate, total_tax_amount),
	}

	if (img) klarnaOrderLine.image_url = img

	return klarnaOrderLine
}

const getShippingCustomLineItems = (customLineItems: CustomLineItem[]) => {
	const shippingCustomLineItems = customLineItems.filter((customLineItem) => {
		const { name, slug } = customLineItem

		return name.includes("shipping") || slug.includes("delivery")
	})

	return shippingCustomLineItems
}

const getKlarnaOrderLineFromShippingCustomLineItem = (customLineItem: CustomLineItem) => {
	const {
		name,
		quantity,
		taxedPrice: {
			totalGross: { centAmount: totalGrossCents },
			totalNet: { centAmount: totalNetCents },
		},
		taxRate: { rate: taxRate },
	} = customLineItem

	const unit_price = totalGrossCents / quantity
	const total_tax_amount = totalGrossCents - totalNetCents

	const klarnaOrderLine: KlarnaOrderLine = {
		name,
		quantity,
		// reference: id,
		tax_rate: taxRate * 100 ** 2,
		total_amount: totalGrossCents, // Includes tax and discounts
		type: "shipping_fee",
		unit_price, // Minor units. Includes tax, excludes discount
		total_tax_amount,
	}

	return klarnaOrderLine
}

const getKlarnaAddress = (address: FormAddress) => {
	const {
		firstName,
		lastName,
		streetName,
		streetNumber,
		postalCode,
		city,
		country,
		email,
		//phone,
		isCompany,
		company,
	} = address

	const klarnaAddress: KlarnaAddress = {
		city,
		country,
		email,
		family_name: lastName,
		given_name: firstName,
		//phone,
		postal_code: postalCode,
		street_address: `${streetName} ${streetNumber}`,
	}

	if (isCompany) klarnaAddress.organization_name = company

	return klarnaAddress
}

const getKlarnaCustomer = (address: FormAddress) => {
	const { salutation, isCompany, vatId } = address

	const gender = salutation === "Mr" ? "male" : "female"

	const klarnaCustomer: KlarnaCustomer = {
		gender,
	}

	if (isCompany) klarnaCustomer.vat_id = vatId

	return klarnaCustomer
}

const getKlarnaSessionLocale = (
	billingAddressCountryCode: CountryCode,
	language = "de",
): KlarnaSessionLocale => {
	switch (billingAddressCountryCode) {
		case "AT":
			switch (language) {
				case "de":
					return "de-AT"
				default:
					return "en-AT"
			}
		case "DK":
			switch (language) {
				case "da":
					return "da-DK"
				default:
					return "en-DK"
			}
		case "FI":
			switch (language) {
				case "fi":
					return "fi-FI"
				case "sv":
					return "sv-FI"
				default:
					return "en-FI"
			}
		case "DE":
			switch (language) {
				case "de":
					return "de-DE"
				default:
					return "en-DE"
			}
		case "NL":
			switch (language) {
				case "nl":
					return "nl-NL"
				default:
					return "en-NL"
			}
		case "NO":
			switch (language) {
				case "nb":
					return "nb-NO"
				default:
					return "en-NO"
			}
		case "SE":
			switch (language) {
				case "sv":
					return "sv-SE"
				default:
					return "en-SE"
			}
		case "CH":
			switch (language) {
				case "de":
					return "de-CH"
				case "fr":
					return "fr-CH"
				case "it":
					return "it-CH"
				default:
					return "en-CH"
			}
		case "PL":
			switch (language) {
				case "en":
					return "en-PL"
				default:
					return "pl-PL"
			}
		case "GB":
			return "en-GB"
		case "US":
			return "en-US"
		default:
			return "de-DE"
	}
}

const getMerchantUrls = (host: string, shopLocale: string, cartId: string) => {
	const prefix = process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY === "TRE" ? "" : `/${shopLocale}`

	const merchantUrls: KlarnaMerchantUrls = {
		confirmation: `${host}${prefix}/success?paymentMethod=klarna&cartId=${cartId}&orderId={order.id}&sessionId={session.id}`,
	}

	return merchantUrls
}

const getKlarnaSessionFromCart = (
	cart: Cart,
	billingAddress: FormAddress,
	shippingAddress: FormAddress,
	shippingAddressDiffers: boolean,
	host: string,
) => {
	const {
		lineItems,
		customLineItems,
		taxedPrice: {
			totalGross: { centAmount: totalGrossAmount },
		},
		currencyCode,
	} = cart

	const locale = getKlarnaSessionLocale(billingAddress.country)

	const shippingCustomLineItems = getShippingCustomLineItems(customLineItems)

	const klarnaLineItems = lineItems.map((lineItem) =>
		getKlarnaOrderLineFromLineItem(lineItem, host, locale),
	)
	const klarnaShippingLineItems = shippingCustomLineItems.map(
		getKlarnaOrderLineFromShippingCustomLineItem,
	)

	const order_lines = [...klarnaLineItems, ...klarnaShippingLineItems]
	const order_tax_amount = getKlarnaOrderTaxAmount(order_lines)

	// Minimal required config
	const klarnaSession: KlarnaSessionCreation = {
		order_lines,
		billing_address: getKlarnaAddress(billingAddress),
		customer: getKlarnaCustomer(billingAddress),
		purchase_country: billingAddress.country,
		purchase_currency: currencyCode,
		merchant_data: cart.id,
		locale,
		order_amount: totalGrossAmount,
		order_tax_amount,
	}

	if (shippingAddressDiffers) klarnaSession.shipping_address = getKlarnaAddress(shippingAddress)

	return klarnaSession
}

export const getInitialKlarnaSession = (
	host: string,
	cart: Cart,
	shopCountryCode: CountryCode,
	language: string,
) => {
	const {
		lineItems,
		customLineItems,
		taxedPrice: {
			totalGross: { centAmount: totalGrossAmount },
		},
		currencyCode,
	} = cart

	const shopLocale = `${language}-${shopCountryCode}`
	const locale = getKlarnaSessionLocale(shopCountryCode, language)
	const merchantUrls = getMerchantUrls(host, shopLocale, cart.id)

	const shippingCustomLineItems = getShippingCustomLineItems(customLineItems)

	const klarnaLineItems = lineItems.map((lineItem) =>
		getKlarnaOrderLineFromLineItem(lineItem, host, shopLocale),
	)
	const klarnaShippingLineItems = shippingCustomLineItems.map(
		getKlarnaOrderLineFromShippingCustomLineItem,
	)

	const order_lines = [...klarnaLineItems, ...klarnaShippingLineItems]
	const order_tax_amount = getKlarnaOrderTaxAmount(order_lines)

	const klarnaSession: KlarnaSessionCreation = {
		order_lines,
		purchase_currency: currencyCode,
		merchant_data: cart.id,
		purchase_country: shopCountryCode,
		locale,
		order_amount: totalGrossAmount,
		order_tax_amount,
		merchant_urls: merchantUrls,
	}

	return klarnaSession
}

export default getKlarnaSessionFromCart
