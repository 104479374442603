import React, { useEffect, useState } from "react"
import { AppContext, AppProps } from "next/app"
import { I18nextProvider } from "react-i18next"
import { SnackbarProvider } from "notistack"
import dynamic from "next/dynamic"
import { AppCacheProvider } from "@mui/material-nextjs/v14-pagesRouter"
import { ThemeProvider } from "@mui/material/styles"

import { PageTransitionSpinner } from "../components/Spinner"

import Layout from "components/Layout"
import { DefaultSEO } from "@secureo/common/components/SEO"
import GeneralStructuredData from "@secureo/common/components/StructuredData/GeneralStructuredData"

import { AppContextProvider } from "@secureo/common/context/AppContext/AppContext"
import AppContextController from "@secureo/common/context/AppContext/AppContextController"
import { CheckoutContextProvider } from "@secureo/common/context/CheckoutContext/CheckoutContext"

import useRouteChange from "@secureo/common/hooks/useRouteChange"
import getLocaleAndPathSegmentsFromLocalizedAsPath from "@secureo/common/utils/i18n/getLocaleAndPathSegmentsFromLocalizedAsPath"
import { pushEventToDataLayer } from "@secureo/common/components/GoogleTagManager"

import { CountryCode } from "@secureo/common/typings/CountryCode"

import i18nInstance from "@secureo/common/config/i18n"
import isProduction from "@secureo/common/utils/isProduction"

import theme from "theme"
import getGeoCountry from "@secureo/common/utils/getGeoCountry"
import { getLocalizedPhoneNumber } from "components/i18n/Contact"
import { paymentMethods } from "config/paymentMethods"

import "../styles/global.scss"

const SwitchCountry = dynamic(() => import("components/SwitchCountryDialog"), { ssr: false })

const ContextProviders = ({ asPath, countryCode, children }: ContextProvidersProps) => {
	return (
		<AppContextProvider countryCode={countryCode} language="de">
			<AppContextController asPath={asPath} shopCountryCode={countryCode}>
				<CheckoutContextProvider
					shopCountryCode={countryCode}
					paymentMethods={paymentMethods}
				>
					<I18nextProvider i18n={i18nInstance}>
						<SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
					</I18nextProvider>
				</CheckoutContextProvider>
			</AppContextController>
		</AppContextProvider>
	)
}

interface ContextProvidersProps {
	asPath: string
	countryCode: CountryCode
	children: React.ReactNode
}

const CustomApp = (props: Props) => {
	const { Component, pageProps, err } = props

	// eslint-disable-next-line
	const { asPath, locale, countryCode } = pageProps

	// Workaround for https://github.com/zeit/next.js/issues/8592
	const modifiedPageProps = { ...pageProps, err } as any
	const isChangingRoute = useRouteChange()

	const isCheckout = asPath.includes("checkout")

	const [geoCountryCode, setGetGeoCountry] = useState(null as CountryCode)

	useEffect(() => {
		getGeoCountry().then(setGetGeoCountry).catch(console.error)
	}, [])

	return (
		<AppCacheProvider {...props}>
			<ThemeProvider theme={theme}>
				<ContextProviders asPath={asPath} countryCode={countryCode}>
					<Layout asPath={asPath} locale={locale} countryCode={countryCode}>
						<DefaultSEO asPath={asPath} />
						<PageTransitionSpinner isHidden={isChangingRoute}>
							<Component {...modifiedPageProps} />
						</PageTransitionSpinner>
						<GeneralStructuredData
							locale={locale}
							phoneNumber={getLocalizedPhoneNumber(countryCode)}
						/>
						{!isCheckout && (
							<SwitchCountry
								countryCode={countryCode}
								geoCountryCode={geoCountryCode}
								language="de"
							/>
						)}
					</Layout>
				</ContextProviders>
			</ThemeProvider>
		</AppCacheProvider>
	)
}

CustomApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
	const { asPath, pathname } = ctx
	let pageProps: any = {}

	const { locale, language, countryCode } = getLocaleAndPathSegmentsFromLocalizedAsPath(asPath)

	if (Component.getInitialProps) {
		pageProps = await Component.getInitialProps(ctx)
	}

	const customAppProps: CustomAppProps = {
		// Path information
		asPath,
		pathname,

		// i18n
		locale,
		language,
		countryCode,
	}

	return {
		pageProps: {
			...pageProps,
			...customAppProps,
		},
	}
}

export interface CustomAppProps {
	// Errors
	err?: any

	// Path information
	asPath: string
	pathname: string

	// i18n
	locale: string
	language: string
	countryCode: CountryCode
}

interface Props extends AppProps {
	pageProps: CustomAppProps
	err?: any
}

export function reportWebVitals({ id, name, label, value }) {
	// Only report web vitals in production
	if (isProduction()) {
		pushEventToDataLayer({
			event: "web-vitals",
			event_category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
			event_action: name,
			// Google Analytics metrics must be integers, so the value is rounded.
			// For CLS the value is first multiplied by 1000 for greater precision
			// (note: increase the multiplier for greater precision if needed).
			event_value: Math.round(name === "CLS" ? value * 1000 : value),
			// The `id` value will be unique to the current page load. When sending
			// multiple values from the same page (e.g. for CLS), Google Analytics can
			// compute a total by grouping on this ID (note: requires `eventLabel` to
			// be a dimension in your report).
			event_label: id,
		})
	}
}

export default CustomApp
