import { NextSeoProps } from "next-seo"
import { SEOProps } from "../../../components/SEO"

import { CountryCode } from "@secureo/common/typings/CountryCode"
import formatPrice from "../../forex/formatPrice"
import { flatFeesByCurrencyCode } from "@secureo/common/config/shippingZonesAndCosts"

const getFallbackProductMetaTitle = (productName: string, countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return `${productName} online kaufen | Digitalzylinder-Shop Österreich`
		case "CH":
			return `${productName} online kaufen | Digitalzylinder-Shop Schweiz`
		default:
			return `${productName} online kaufen | Digitalzylinder-Shop Deutschland`
	}
}

const getFallbackProductMetaDescription = (productName: string, countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return `${productName} günstig im Digitalzylinder-Shop Österreich kaufen ✓ Portofrei ab ${formatPrice(
				flatFeesByCurrencyCode.EUR.flatFeeForSmallAmountThresholdGrossCents,
				"de-DE",
			)} ✓ Kauf auf Rechnung ✓ Trusted Shop®`
		case "CH":
			return `${productName} günstig im Digitalzylinder-Shop Schweiz kaufen ✓ Portofrei ab ${formatPrice(
				flatFeesByCurrencyCode.EUR.flatFeeForSmallAmountThresholdGrossCents,
				"de-DE",
			)} ✓ Trusted Shop®`
		default:
			return `${productName} günstig im Digitalzylinder-Shop Deutschland kaufen ✓ Portofrei ab ${formatPrice(
				flatFeesByCurrencyCode.EUR.flatFeeForSmallAmountThresholdGrossCents,
				"de-DE",
			)} ✓ Kauf auf Rechnung ✓ Trusted Shop®`
	}
}

const getProductNextSEOProps = (props: SEOProps, countryCode: CountryCode) => {
	const { title, description, productName } = props

	const productSEOProps: NextSeoProps = {}

	if (title) {
		productSEOProps.title = title
	} else if (productName) {
		productSEOProps.title = getFallbackProductMetaTitle(productName, countryCode)
	}
	if (description) {
		productSEOProps.description = description
	} else if (productName) {
		productSEOProps.description = getFallbackProductMetaDescription(productName, countryCode)
	}

	return productSEOProps
}

export default getProductNextSEOProps
