import React, { useState, useEffect, useRef } from "react"

import useIntersectionObserver from "@secureo/common/hooks/useIntersectionObserver"

import { skeletonSVG } from "svg/base64Svgs"

const LazyLoadedImage = ({
	src,
	title,
	alt,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onIntersection = () => {},
	...props
}: Props &
	React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
	const targetRef = useRef(null)
	const [showImage, setShowImage] = useState(false)
	const isIntersecting = useIntersectionObserver(
		targetRef,
		process.browser ? document.querySelector("body") : null,
	)

	useEffect(() => {
		if (isIntersecting && !showImage) setShowImage(true)
	}, [isIntersecting, showImage])

	useEffect(() => {
		if (isIntersecting && onIntersection) {
			onIntersection()
		}
	}, [isIntersecting, onIntersection])

	return (
		<img
			ref={targetRef}
			src={showImage ? src : skeletonSVG}
			title={title}
			alt={alt}
			{...props}
		/>
	)
}

interface Props {
	src: string
	title: string
	alt: string
	/**
	 * Function which will be called every time the image is intersected
	 */
	onIntersection?: () => void
}

export default LazyLoadedImage
