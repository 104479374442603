import { Container, Box } from "@mui/material"
import Icon from "@mdi/react"
import { mdiHeadset, mdiPackageVariant, mdiEmail } from "@mdi/js"

import { makeStyles } from "makeStyles"
import formatPrice from "@secureo/common/utils/forex/formatPrice"
import { flatFeesByCurrencyCode } from "@secureo/common/config/shippingZonesAndCosts"
import { emailAddress } from "config/contact"

const useStyles = makeStyles()((theme) => ({
	breakPointMd: {
		display: "block",
		[theme.breakpoints.down("lg")]: {
			display: "none",
		},
	},
}))

const TopBar = () => {
	const { classes } = useStyles()

	return (
		<div
			className={classes.breakPointMd}
			style={{ background: "#EBF1F7", color: "#263739", fontSize: "0.8rem" }}
		>
			<Box py={1} mb={2}>
				<Container
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box ml={1} style={{ display: "flex", alignItems: "center" }}>
						<Icon path={mdiHeadset} size={0.7} color="#4691CE" />
						<Box ml={1}>Kompetente Beratung</Box>
					</Box>
					<Box ml={1} style={{ display: "flex", alignItems: "center" }}>
						<Icon path={mdiPackageVariant} size={0.7} color="#4691CE" />
						<Box ml={1}>
							Kostenloser Versand ab{" "}
							{formatPrice(
								flatFeesByCurrencyCode.EUR.flatFeeForSmallAmountThresholdGrossCents,
								"de-DE",
							)}
						</Box>
					</Box>
					<Box ml={1} style={{ display: "flex", alignItems: "center" }}>
						<Icon path={mdiEmail} size={0.7} color="#4691CE" />
						<Box ml={1}>
							<a
								href={`mailto:${emailAddress}`}
								style={{ textDecoration: "none", color: "#263739" }}
							>
								{emailAddress}
							</a>
						</Box>
					</Box>
				</Container>
			</Box>
		</div>
	)
}

export default TopBar
