import { NextSeoProps } from "next-seo"

import getProductSEOProps from "./getProductSEOProps"
import getCategorySEOProps from "./getCategorySEOProps"
import { getFallbackMetaTitle, getFallbackMetaDescription } from "./fallbackSEOProps"

import { SEOProps } from "../../../components/SEO"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const getPageTypeSpecificSEOProps = (props: SEOProps, countryCode: CountryCode) => {
	const { title, description, pageType, appendLocalizedShopNameWithPipe = false } = props

	switch (pageType) {
		case "product":
			return getProductSEOProps(props, countryCode)

		case "category":
			return getCategorySEOProps(props, countryCode)

		default: {
			const pageSpecificSEOProps: NextSeoProps = {}

			if (title)
				pageSpecificSEOProps.title = `${title}${
					appendLocalizedShopNameWithPipe ? ` | Digitalzylinder-Shop ${countryCode}` : ""
				}`
			else pageSpecificSEOProps.title = getFallbackMetaTitle(countryCode)

			if (description) pageSpecificSEOProps.description = description
			else pageSpecificSEOProps.description = getFallbackMetaDescription(countryCode)

			return pageSpecificSEOProps
		}
	}
}

export default getPageTypeSpecificSEOProps
