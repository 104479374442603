import { useState, useEffect, useContext } from "react"
import Router from "next/router"

import useTranslation from "./useTranslation"

import AppContext from "@secureo/common/context/AppContext/AppContext"

const useAuthentication = () => {
	const { isLoggedIn, isInitialized } = useContext(AppContext)
	const { locale } = useTranslation()
	const [showPage, setShowPage] = useState(null)

	const prefix = process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY === "TRE" ? "" : `/${locale}`

	useEffect(() => {
		if (isInitialized && showPage === null) {
			if (isLoggedIn) setShowPage(true)
			else {
				setShowPage(false)
				Router.push(`${prefix}/`)
			}
		}
	}, [isInitialized, isLoggedIn, showPage, locale])

	return showPage
}

export default useAuthentication
