import React, { useContext } from "react"
import { Box, Container, Theme, Button } from "@mui/material"
import { makeStyles } from "makeStyles"

import Icon from "@mdi/react"
import { mdiMenu, mdiClose } from "@mdi/js"

import NavCategoryList from "./NavCategoryList"
import SearchBox from "components/Algolia/SearchBox/SearchBox"

import useToggle from "@secureo/common/hooks/useToggle"

import useWindowSize from "@secureo/common/hooks/useWindowSize"
import AppContext from "@secureo/common/context/AppContext/AppContext"

import { NavCategory } from "@secureo/common/utils/commerceTools/graphql/queries/fetchNavCategories"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const useStyles = makeStyles()((theme: Theme) => ({
	background: {
		background:
			"linear-gradient(90deg, rgba(59,121,171,1) 0%, rgba(70,145,206,1) 53%, rgba(90,164,224,1) 100%)",
	},
	root: {
		display: "flex",
	},
	drawer: {
		width: "100%",
		flexShrink: 0,
	},
	drawerPaper: {
		width: "100%",
		marginTop: 118,
	},
	drawerContainer: {
		overflow: "auto",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}))

const NavigationMobile = ({ navCategories, locale, countryCode }: Props) => {
	const { classes } = useStyles()
	const [isOpen, toggleIsOpen] = useToggle()

	const { dispatch, showHeader } = useContext(AppContext)
	const { innerWidth } = useWindowSize()
	const isTablet = innerWidth < 960

	const toggleHeader = () => {
		if (isTablet)
			dispatch({
				type: "SEARCHBOX_IS_OPEN",
			})
	}

	return (
		<Box py={1} className={classes.background}>
			<Container>
				<div style={{ display: "flex" }}>
					<div
						style={{
							width: "15%",
						}}
					>
						{showHeader ? (
							<Button
								onClick={toggleIsOpen}
								style={{
									display: "flex",
									textAlign: "left",
									padding: 6,
									width: 20,
									marginLeft: -10,
								}}
							>
								<Icon
									path={isOpen ? mdiClose : mdiMenu}
									size={1.6}
									color="white"
									style={{
										padding: 0,
										marginTop: -6,
										marginBottom: -10,
									}}
								/>
							</Button>
						) : (
							<Button
								onClick={toggleHeader}
								style={{
									display: "flex",
									textAlign: "left",
									padding: 6,
									width: 20,
									marginLeft: -10,
								}}
							>
								<Icon
									path={mdiClose}
									size={1.6}
									color="white"
									style={{
										padding: 0,
										marginTop: -6,
										marginBottom: -10,
									}}
								/>
							</Button>
						)}
						{isOpen && (
							<NavCategoryList
								categories={navCategories}
								isOpen={isOpen}
								isRootCategory
								onClose={toggleIsOpen}
								countryCode={countryCode}
							/>
						)}
					</div>
					<div style={{ width: "85%" }}>
						<SearchBox locale={locale} />
					</div>
				</div>
			</Container>
		</Box>
	)
}

interface Props {
	navCategories: NavCategory[]
	locale: string
	countryCode: CountryCode
}

export default NavigationMobile
