import { Fragment, useState } from "react"
import { Drawer, Box, List, ListItemText, ListItemIcon, ListItemButton } from "@mui/material"
import { makeStyles } from "makeStyles"
import Router from "next/router"

import Icon from "@mdi/react"
import { mdiChevronRight, mdiRadioboxMarked, mdiRadioboxBlank } from "@mdi/js"

import Link from "components/i18n/Link"

import { NavCategory } from "@secureo/common/utils/commerceTools/graphql/queries/fetchNavCategories"

import CountryFlag from "components/i18n/CountryFlag"
import { CountryCode } from "@secureo/common/typings/CountryCode"

import useTranslation from "@secureo/common/hooks/useTranslation"
import { shopCountryCodes } from "@secureo/common/utils/i18n/supportedLocalesAndCurrencyCodes"

const useStyles = makeStyles()({
	background: {
		background:
			"linear-gradient(90deg, rgba(59,121,171,1) 0%, rgba(70,145,206,1) 53%, rgba(90,164,224,1) 100%)",
	},
	root: {
		display: "flex",
	},
	drawer: {
		width: "100%",
		flexShrink: 0,
	},
	drawerPaper: {
		width: "100%",
		marginTop: 126,
	},
	listItem: {
		paddingTop: 10,
		paddingBottom: 10,
		borderBottom: "2px solid whitesmoke",
		color: "#263739",
	},
})

const getLocalizedUrl = (url: string, locale: string) => {
	const localizedUrl = url.replace(/\/\w+-\w+/, `/${locale}`)

	return localizedUrl
}

const AdditionalRootCategoryItems = ({
	onClose,
	countryCode,
}: AdditionalRootCategoryItemsProps) => {
	const { classes } = useStyles()
	const [isOpen, setIsOpen] = useState(false)

	const openNavbar = () => {
		setIsOpen(true)
	}

	const closeNavbar = () => {
		setIsOpen(false)
	}

	const navigateToLocalizedUrl = async (locale: string) => {
		const { route, asPath } = Router.router

		if (asPath.includes("/cart") || asPath.includes("/checkout")) {
			const localizedUrl = getLocalizedUrl(window.location.href, locale)
			window.location.href = localizedUrl
		}

		const localizedUrl = getLocalizedUrl(asPath, locale)

		await Router.push(route, localizedUrl)
	}

	const switchToCountry = async (selectedCountryCode: CountryCode) => {
		if (selectedCountryCode !== countryCode) {
			await navigateToLocalizedUrl(`de-${selectedCountryCode}`)
		}
	}

	const { t } = useTranslation("countries")

	return (
		<>
			{/* <Link href="/locking-system-advisor">
				<ListItem button className={classes.listItem} onClick={onClose}>
					<ListItemText primary="Systemberater" />
					<ListItemIcon>
						<Icon path={mdiChevronRight} size={1} color="#263739" />
					</ListItemIcon>
				</ListItem>
			</Link> */}
			<Box style={{ background: "whitesmoke", height: 15 }} />
			<Link href="/locking-system">
				<ListItemButton
					className={classes.listItem}
					onClick={onClose}
					style={{ borderTop: "2px solid whitesmoke" }}
				>
					<ListItemText primary="Schließanlage planen" />
					<ListItemIcon>
						<Icon path={mdiChevronRight} size={1} color="#263739" />
					</ListItemIcon>
				</ListItemButton>
			</Link>

			<Link href="/c/schluessel-nachmachen">
				<ListItemButton className={classes.listItem} onClick={onClose}>
					<ListItemText primary="Nachbestellungen" />
					<ListItemIcon>
						<Icon path={mdiChevronRight} size={1} color="#263739" />
					</ListItemIcon>
				</ListItemButton>
			</Link>

			<Link href="/help">
				<ListItemButton className={classes.listItem} onClick={onClose}>
					<ListItemText primary="Hilfe" />
					<ListItemIcon>
						<Icon path={mdiChevronRight} size={1} color="#263739" />
					</ListItemIcon>
				</ListItemButton>
			</Link>

			<ListItemButton className={classes.listItem} onClick={openNavbar}>
				<ListItemText
					primary={
						<div style={{ display: "flex", alignItems: "center" }}>
							<Box mr={1} style={{ width: 20 }}>
								<CountryFlag countryCode={countryCode} />
							</Box>
							Land ändern
						</div>
					}
				/>
				<ListItemIcon>
					<Icon path={mdiChevronRight} size={1} color="#263739" />
				</ListItemIcon>
			</ListItemButton>
			<Drawer
				open={isOpen}
				onClose={onClose}
				className={classes.drawer}
				classes={{
					paper: classes.drawerPaper,
				}}
				BackdropProps={{ invisible: true }}
			>
				<List style={{ paddingBottom: 150 }}>
					<ListItemButton
						onClick={closeNavbar}
						style={{
							display: "flex",
							alignItems: "center",
							borderBottom: "2px solid whitesmoke",
						}}
						className={classes.listItem}
					>
						<Box mr={2} style={{ color: "#4691CE", marginTop: -6 }}>
							<strong>&#x27F5;</strong>
						</Box>
						<ListItemText primary="Zurück" />
					</ListItemButton>
					{shopCountryCodes.map((shopCountryCode) => {
						return (
							<ListItemButton
								key={shopCountryCode}
								className={classes.listItem}
								onClick={() => switchToCountry(shopCountryCode)}
							>
								<ListItemText
									primary={
										<div style={{ display: "flex", alignItems: "center" }}>
											<Box mr={1} style={{ width: 20 }}>
												<CountryFlag countryCode={shopCountryCode} />
											</Box>
											<div>{t(shopCountryCode)}</div>
										</div>
									}
								/>
								<ListItemIcon>
									<Icon
										path={
											shopCountryCode === countryCode
												? mdiRadioboxMarked
												: mdiRadioboxBlank
										}
										size={1}
										color="#4691CE"
									/>
								</ListItemIcon>
							</ListItemButton>
						)
					})}
				</List>
			</Drawer>
		</>
	)
}

interface AdditionalRootCategoryItemsProps {
	onClose: () => void
	countryCode: CountryCode
}

const AdditionalSubCategoryItems = ({
	slug,
	onClose,
	onBackNavigation,
}: AdditionalSubCategoryItemsProps) => {
	const { classes } = useStyles()

	return (
		<>
			<ListItemButton
				onClick={onBackNavigation}
				style={{
					display: "flex",
					alignItems: "center",
					borderBottom: "2px solid whitesmoke",
				}}
				className={classes.listItem}
			>
				<Box mr={2} style={{ color: "#4691CE", marginTop: -6 }}>
					<strong>&#x27F5;</strong>
				</Box>
				<ListItemText primary="Zurück" />
			</ListItemButton>

			<Link href={`/c/${slug}`} onClick={onClose}>
				<ListItemButton
					onClick={onClose}
					style={{
						borderBottom: "2px solid whitesmoke",
					}}
					className={classes.listItem}
				>
					<ListItemText
						primary={<strong>Alle Produkte anzeigen</strong>}
						style={{ fontWeight: 800 }}
					/>
					<ListItemIcon>
						<Icon path={mdiChevronRight} size={1} color="#263739" />
					</ListItemIcon>
				</ListItemButton>
			</Link>
		</>
	)
}

interface AdditionalSubCategoryItemsProps {
	slug: string
	onClose: () => void
	onBackNavigation: () => void
}

const NavCategoryList = ({
	slug,
	categories,
	isRootCategory,
	onClose,
	isOpen,
	countryCode,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onBackNavigation = () => {},
}: Props) => {
	const { classes } = useStyles()
	const [openChildCategoryId, setOpenChildCategoryId] = useState(null)

	const isHiddenCategory = categories.length === 1 && categories?.[0]?.slug?.includes("alle")
	const actualCategories = isHiddenCategory ? categories?.[0]?.children ?? [] : categories

	return (
		<Drawer
			open={isOpen}
			onClose={onClose}
			className={classes.drawer}
			classes={{
				paper: classes.drawerPaper,
			}}
			BackdropProps={{ invisible: true }}
		>
			<List style={{ paddingBottom: 150 }}>
				{!isRootCategory && (
					<AdditionalSubCategoryItems
						slug={slug}
						onClose={onClose}
						onBackNavigation={onBackNavigation}
					/>
				)}

				{actualCategories.map((category) => {
					const { name, slug, children = [] } = category

					const hasChildren = children.length > 0
					const isOpen = openChildCategoryId === slug
					const onBackNavigation = () => {
						setOpenChildCategoryId(null)
					}

					const openChildCategory = () => setOpenChildCategoryId(slug)

					return (
						<Fragment key={slug}>
							{hasChildren ? (
								<ListItemButton
									onClick={openChildCategory}
									className={classes.listItem}
								>
									<ListItemText primary={name} />
									<ListItemIcon>
										<Icon path={mdiChevronRight} size={1} color="#263739" />
									</ListItemIcon>
								</ListItemButton>
							) : (
								<Link href={`/c/${slug}`} onClick={onClose}>
									<ListItemButton className={classes.listItem}>
										<ListItemText primary={name} />
										<ListItemIcon>
											<Icon path={mdiChevronRight} size={1} color="#263739" />
										</ListItemIcon>
									</ListItemButton>
								</Link>
							)}

							{hasChildren && (
								<NavCategoryList
									slug={slug}
									categories={children}
									isOpen={isOpen}
									onClose={onClose}
									onBackNavigation={onBackNavigation}
									countryCode={countryCode}
								/>
							)}
						</Fragment>
					)
				})}
				{isRootCategory && (
					<AdditionalRootCategoryItems countryCode={countryCode} onClose={onClose} />
				)}
			</List>
		</Drawer>
	)
}

interface Props {
	slug?: string
	categories: NavCategory[]
	isRootCategory?: boolean
	onClose: () => void
	onBackNavigation?: () => void
	isOpen: boolean
	countryCode: CountryCode
}

export default NavCategoryList
