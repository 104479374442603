import { CSSProperties } from "react"
import { ScaleLoader } from "react-spinners"
import { Box } from "@mui/material"

const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
	borderColor: "none",
}

const Spinner = () => {
	return (
		<div style={{ margin: "auto", textAlign: "center" }}>
			<ScaleLoader
				cssOverride={override}
				height={35}
				width={4}
				radius={2}
				color={"#4691CE"}
				loading
			/>
		</div>
	)
}

export const FullPageSpinner = () => {
	return (
		<Box style={{ height: "700px" }}>
			<div
				style={{
					margin: "auto",
					textAlign: "center",
					position: "absolute",
					left: "50%",
					top: "40%",
					transform: "translate(-50%, -50%)",
				}}
			>
				<ScaleLoader
					cssOverride={override}
					height={35}
					width={4}
					radius={2}
					color={"#4691CE"}
					loading
				/>
			</div>
		</Box>
	)
}

export const PageTransitionSpinner = ({ isHidden, children }: Props) => {
	return (
		<>
			{isHidden && (
				<Box style={{ height: "700px" }}>
					<div
						style={{
							margin: "auto",
							textAlign: "center",
							position: "relative",
							left: "50%",
							top: "180px",
							transform: "translate(-50%, -50%)",
							width: "100%",
						}}
					>
						<ScaleLoader
							cssOverride={override}
							height={35}
							width={4}
							radius={2}
							color={"#4691CE"}
							loading
						/>
					</div>
				</Box>
			)}
			<div style={isHidden ? { display: "none" } : {}}>{children}</div>
		</>
	)
}

interface Props {
	isHidden: boolean
	children: React.ReactNode
}

export default Spinner
