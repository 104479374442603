import React from "react"
import formatPrice from "@secureo/common/utils/forex/formatPrice"

const Price = ({ priceInEuroCents, style = {} }: Props) => {
	const formattedPrice = formatPrice(priceInEuroCents, "de", "EUR")

	return <span style={{ ...style, whiteSpace: "nowrap" }}>{formattedPrice}</span>
}

interface Props {
	priceInEuroCents: number
	style?: React.CSSProperties
}

export default Price
