import { useState, useEffect } from "react"
import { Namespace } from "i18next"
import { useTranslation, UseTranslationOptions } from "react-i18next"

import getLanguageAndCountryCodeFromLocale from "../utils/i18n/getLanguageAndCountryCodeFromLocale"

import { CountryCode } from "@secureo/common/typings/CountryCode"

const useTranslationCustom = (ns?: Namespace, options?: UseTranslationOptions) => {
	const { i18n, t } = useTranslation(ns, options)
	const [language, setLanguage] = useState(
		getLanguageAndCountryCodeFromLocale(i18n.language).language,
	)
	const [countryCode, setCountryCode] = useState(
		getLanguageAndCountryCodeFromLocale(i18n.language).countryCode as CountryCode,
	)

	useEffect(() => {
		const { language, countryCode } = getLanguageAndCountryCodeFromLocale(i18n.language)
		setLanguage(language)
		setCountryCode(countryCode as any)
	}, [i18n.language])

	return {
		i18n,
		t,
		locale: i18n.language,
		language,
		countryCode,
	}
}

export default useTranslationCustom
