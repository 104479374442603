import { Address, AddressInput } from "@secureo/common/typings/generated/commercetools-graphql"
import { FormAddress } from "@secureo/common/typings/FormAddress"
import { CountryCode } from "@secureo/common/typings/CountryCode"

export const getFormAddressFromCommerceToolsAddress = (commerceToolsAddress: Partial<Address>) => {
	if (!commerceToolsAddress)
		return {
			email: "",
			country: "DE",
			salutation: "Mr",
		} as FormAddress

	const {
		salutation,
		firstName,
		lastName,
		streetName,
		streetNumber,
		postalCode,
		city,
		country,
		email,
		phone,
		company,
		department,
		externalId: vatId,
		additionalAddressInfo,
	} = commerceToolsAddress

	const formAddress: FormAddress = {
		isCompany: !!company,
		salutation: salutation || "Mr",
		firstName: firstName || "",
		lastName: lastName || "",
		streetName: streetName || "",
		streetNumber: streetNumber || "",
		postalCode: postalCode || "",
		city: city || "",
		country: (country || "DE") as CountryCode,
		email: email || "",
		phone: phone || "",
		company: company || "",
		vatId: vatId || "",
		department: department || "",
		additionalAddressInfo: additionalAddressInfo || "",
	}

	return formAddress
}

export const mergeAndFormatAddresses = (
	cartAddress: Address,
	customerAddress: Address,
	shopCountryCode: CountryCode,
) => {
	const addressA = getFormAddressFromCommerceToolsAddress(cartAddress)
	const addressB = getFormAddressFromCommerceToolsAddress(customerAddress)

	const mergedAddress: FormAddress = {
		isCompany: !!addressA.company || !!addressB.company,
		salutation: addressA.salutation || addressB.salutation || "Mr",
		firstName: addressA.firstName || addressB.firstName || "",
		lastName: addressA.lastName || addressB.lastName || "",
		streetName: addressA.streetName || addressB.streetName || "",
		streetNumber: addressA.streetNumber || addressB.streetNumber || "",
		postalCode: addressA.postalCode || addressB.streetNumber || "",
		city: addressA.city || addressB.city || "",
		country: addressB.country || shopCountryCode,
		email: addressA.email || addressB.email || "",
		phone: addressA.phone || addressB.phone || "",
		company: addressA.company || addressB.company || "",
		vatId: addressA.vatId || addressB.vatId || "",
		department: addressA.department || addressB.department || "",
		additionalAddressInfo:
			addressA.additionalAddressInfo || addressB.additionalAddressInfo || "",
	}

	return mergedAddress
}

export const getCommerceToolsGraphQLAddressInputFromFormAddress = (formAddress: FormAddress) => {
	const {
		isCompany,
		salutation,
		firstName,
		lastName,
		streetName,
		streetNumber,
		postalCode,
		city,
		country,
		email,
		phone,
		company,
		vatId,
		department,
		additionalAddressInfo,
	} = formAddress

	const commerceToolsAddress: AddressInput = {
		salutation,
		firstName,
		lastName,
		streetName,
		streetNumber,
		postalCode,
		city,
		country,
		phone,
		email,
		company: isCompany && company ? company : null,
		department: isCompany && department ? department : null,
		externalId: isCompany && vatId ? vatId : null,
		additionalAddressInfo,
	}

	return commerceToolsAddress
}
