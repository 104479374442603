import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

// A better alternative would be to import the json files in the relevant components

// Languages
import languagesDe from "@secureo/common/utils/i18n/locales/de/languages.json"
// import languagesEn from "public/locales/en/languages.json"

// Countries
import countriesDe from "@secureo/common/utils/i18n/locales/de/countries.json"
// import countriesEn from "public/locales/en/countries.json"

// Feedback
import feedbackDe from "@secureo/common/utils/i18n/locales/de/feedback.json"
// import feedbackEn from "public/locales/en/feedback.json"

// Address
import addressDe from "@secureo/common/utils/i18n/locales/de/address.json"
// import addressEn from "public/locales/en/address.json"

// Product
import productDe from "@secureo/common/utils/i18n/locales/de/product.json"
// import productEn from "public/locales/en/product.json"

// LockingSystem
import lockingSystemDe from "@secureo/common/utils/i18n/locales/de/lockingSystem.json"
// import productEn from "public/locales/en/product.json"

const resources = {
	de: {
		languages: languagesDe,
		countries: countriesDe,
		feedback: feedbackDe,
		address: addressDe,
		product: productDe,
		lockingSystem: lockingSystemDe,
	},
	// en: {
	// 	common: commonEn,
	// 	footer: footerEn,
	// 	languages: languagesEn,
	// 	countries: countriesEn,
	// },
}

const detection = {
	// Order and from where user language should be detected
	order: ["querystring", "path", "localStorage", "cookie", "navigator", "htmlTag"],

	// Keys or params to lookup language from
	lookupQuerystring: "i18n",
	lookupCookie: "i18n",
	lookupLocalStorage: "i18n",
	lookupFromPathIndex: 0,

	// Cache user language on
	caches: ["localStorage", "cookie"],
	// excludeCacheFor: ["cimode"], // Languages to not persist (cookie, localStorage)
}

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		detection,
		fallbackLng: "de-DE",
		defaultNS: "common",
		interpolation: {
			escapeValue: false, // React already safes from xss
		},
	})

export default i18n
