import { useState, useEffect } from "react"

import isBrowser from "../utils/isBrowser"
import useDebounce from "./useDebounce"

const fallBackSize = 0

const getDocumentHeight = () => {
	const body = document.body
	const html = document.documentElement

	const documentHeight = Math.max(
		body.scrollHeight,
		body.offsetHeight,
		html.clientHeight,
		html.scrollHeight,
		html.offsetHeight,
	)

	return documentHeight
}

const getWindowSize = () => ({
	innerWidth: isBrowser() ? window.innerWidth : fallBackSize,
	innerHeight: isBrowser() ? window.innerHeight : fallBackSize,
	outerWidth: isBrowser() ? window.outerWidth : fallBackSize,
	outerHeight: isBrowser() ? window.outerHeight : fallBackSize,
	documentHeight: isBrowser() ? getDocumentHeight() : fallBackSize,
})

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState(getWindowSize())
	const debouncedWindowSize = useDebounce(windowSize, 100)

	const handleResize = () => {
		setWindowSize(getWindowSize())
	}

	useEffect(() => {
		setWindowSize(getWindowSize())
		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	return debouncedWindowSize
}

export default useWindowSize
