import React from "react"

import Austria from "@secureo/common/svg/flags/Austria.svg"
import Germany from "@secureo/common/svg/flags/Germany.svg"
import Switzerland from "@secureo/common/svg/flags/Switzerland.svg"

import Belgium from "@secureo/common/svg/flags/Belgium.svg"
import Bulgaria from "@secureo/common/svg/flags/Bulgaria.svg"
import Croatia from "@secureo/common/svg/flags/Croatia.svg"
import CzechRepublic from "@secureo/common/svg/flags/CzechRepublic.svg"
import Denmark from "@secureo/common/svg/flags/Denmark.svg"
import Estonia from "@secureo/common/svg/flags/Estonia.svg"
import Finland from "@secureo/common/svg/flags/Finland.svg"
import France from "@secureo/common/svg/flags/France.svg"
import Greece from "@secureo/common/svg/flags/Greece.svg"
import Hungary from "@secureo/common/svg/flags/Hungary.svg"
import Ireland from "@secureo/common/svg/flags/Ireland.svg"
import Italy from "@secureo/common/svg/flags/Italy.svg"
import Latvia from "@secureo/common/svg/flags/Latvia.svg"
import Lithuania from "@secureo/common/svg/flags/Lithuania.svg"
import Luxembourg from "@secureo/common/svg/flags/Luxembourg.svg"
import Liechtenstein from "@secureo/common/svg/flags/Liechtenstein.svg"
import Netherlands from "@secureo/common/svg/flags/Netherlands.svg"
import Norway from "@secureo/common/svg/flags/Norway.svg"
import Poland from "@secureo/common/svg/flags/Poland.svg"
import Portugal from "@secureo/common/svg/flags/Portugal.svg"
import Romania from "@secureo/common/svg/flags/Romania.svg"
import Slovakia from "@secureo/common/svg/flags/Slovakia.svg"
import Slovenia from "@secureo/common/svg/flags/Slovenia.svg"
import Spain from "@secureo/common/svg/flags/Spain.svg"
import Sweden from "@secureo/common/svg/flags/Sweden.svg"
import UnitedKingdom from "@secureo/common/svg/flags/UnitedKingdom.svg"

import { CountryCode } from "@secureo/common/typings/CountryCode"

// eslint-disable-next-line react/prop-types
const CountryFlag = ({ countryCode, style }: Props) => {
	switch (countryCode) {
		case "AT":
			return <Austria {...style} />
		case "DE":
			return <Germany {...style} />
		case "CH":
			return <Switzerland {...style} />
		case "BE":
			return <Belgium />
		case "BG":
			return <Bulgaria />
		case "HR":
			return <Croatia />
		// case "CH":
		// 	return <Cyprus />
		case "CZ":
			return <CzechRepublic />
		case "DK":
			return <Denmark />
		case "EE":
			return <Estonia />
		case "FI":
			return <Finland />
		case "FR":
			return <France />
		case "GR":
			return <Greece />
		case "HU":
			return <Hungary />
		case "IE":
			return <Ireland />
		case "IT":
			return <Italy />
		case "LV":
			return <Latvia />
		case "LT":
			return <Lithuania />
		case "LU":
			return <Luxembourg />
		case "LI":
			return <Liechtenstein />
		case "NL":
			return <Netherlands />
		case "NO":
			return <Norway />
		case "PL":
			return <Poland />
		case "PT":
			return <Portugal />
		case "RO":
			return <Romania />
		case "SK":
			return <Slovakia />
		case "SI":
			return <Slovenia />
		case "ES":
			return <Spain />
		case "SE":
			return <Sweden />
		case "GB":
			return <UnitedKingdom />
		default:
			return null
	}
}

interface Props {
	countryCode: CountryCode
	style?: React.CSSProperties
}

export default CountryFlag
