import { NextSeoProps } from "next-seo"
import { SEOProps } from "../../../components/SEO"
import { CountryCode } from "@secureo/common/typings/CountryCode"
import formatPrice from "../../forex/formatPrice"
import { flatFeesByCurrencyCode } from "@secureo/common/config/shippingZonesAndCosts"

const getFallbackCategoryMetaTitle = (categoryName: string, countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return `${categoryName} online kaufen | Digitalzylinder-Shop Österreich`
		case "CH":
			return `${categoryName} online kaufen | Digitalzylinder-Shop Schweiz`
		default:
			return `${categoryName} online kaufen | Digitalzylinder-Shop Deutschland`
	}
}

const getFallbackCategoryMetaDescription = (categoryName: string, countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return `${categoryName} günstig im Digitalzylinder-Shop Österreich kaufen ✓ Portofrei ab ${formatPrice(
				flatFeesByCurrencyCode.EUR.flatFeeForSmallAmountThresholdGrossCents,
				"de-DE",
			)} ✓ Kauf auf Rechnung ✓ Trusted Shop®`
		case "CH":
			return `${categoryName} günstig im Digitalzylinder-Shop Schweiz kaufen ✓ Portofrei ab ${formatPrice(
				flatFeesByCurrencyCode.EUR.flatFeeForSmallAmountThresholdGrossCents,
				"de-DE",
			)} ✓ Trusted Shop®`
		default:
			return `${categoryName} günstig im Digitalzylinder-Shop Deutschland kaufen ✓ Portofrei ab ${formatPrice(
				flatFeesByCurrencyCode.EUR.flatFeeForSmallAmountThresholdGrossCents,
				"de-DE",
			)} ✓ Kauf auf Rechnung ✓ Trusted Shop®`
	}
}

const getCategoryNextSEOProps = (props: SEOProps, countryCode: CountryCode) => {
	const { title, description, categoryName } = props

	const categorySEOProps: NextSeoProps = {}

	if (title) {
		categorySEOProps.title = title
	} else if (categoryName) {
		categorySEOProps.title = getFallbackCategoryMetaTitle(categoryName, countryCode)
	}
	if (description) {
		categorySEOProps.description = description
	} else if (categoryName) {
		categorySEOProps.description = getFallbackCategoryMetaDescription(categoryName, countryCode)
	}

	return categorySEOProps
}

export default getCategoryNextSEOProps
