import React from "react"
import { Box, FormControlLabel, Checkbox, TextField } from "@mui/material"
import Button from "components/Buttons/Button"

import OrderOverview from "./OrderOverview"
import Link from "components/i18n/Link"

import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"

import { checkout } from "@secureo/common/context/CheckoutContext/actions"
import { NEXT_PUBLIC_HOST } from "utils/envVariables"

import { CheckoutContextState } from "@secureo/common/context/CheckoutContext/CheckoutContext"
import { Cart, LineItem } from "@secureo/common/typings/Cart"
import { AppContextState } from "@secureo/common/context/AppContext/AppContext"

export const blurredStyle = {
	color: "transparent",
	textShadow: "0 0 3px rgba(0, 0, 0, 0.75)",
}

const customDesignKeySkus = [
	"EVVA-M-4KS-1DESIGN",
	"EVVA-M-EPS-1DESIGN",
	"EVVA-M-ICS-1DESIGN",
	"EVVA-M-MCS-1DESIGN",
	"EVVA-M-ICS-3DESIGN",
	"EVVA-M-MCS-3DESIGN",
	"EVVA-M-4KS-3DESIGN",
	"EVVA-M-EPS-3DESIGN",
]

const hasCustomDesignKeysInCart = (lineItems: LineItem[]) => {
	const lineItemSkus = lineItems.map((lineItem) => lineItem.variant.sku)

	return lineItemSkus.some((lineItemSku) => customDesignKeySkus.includes(lineItemSku))
}

const AcceptTermsAndConditions = () => {
	return (
		<div style={{ color: "grey" }}>
			Mit dem Kauf stimme ich der{" "}
			<Link href="/help/data-policy" target="_blank">
				Datenschutzerklärung
			</Link>{" "}
			und den{" "}
			<Link href="/help/terms-and-conditions" target="_blank">
				AGB
			</Link>{" "}
			zu.
		</div>
	)
}

const OrderOverviewAndCheckout = ({
	checkoutContext,
	appContext,
	cart,
	setIsPrepaidDiscounted,
}: Props) => {
	const { hasOrderAnnotation, orderAnnotation, isLoading, dispatch } = checkoutContext

	const { t } = useTranslation("feedback")
	const { enqueueSnackbar } = useSnackbar()

	const {
		lineItems,
		customLineItems,
		taxedPrice: {
			totalGross: { centAmount: totalGrossCents },
		},
		lockingSystems,
	} = cart

	const toggleHasOrderAnnotation = () => {
		dispatch({ type: "TOGGLE_HAS_ORDER_ANNOTATIONS" })
	}

	const onOrderAnnotationChange = (e) => {
		const { value: orderAnnotation } = e.target

		dispatch({
			type: "SET_ORDER_ANNOTATION",
			payload: orderAnnotation,
		})
	}

	const enqueueSnackbarTranslatedSnackbarWarning = (i18nKey: string) => {
		enqueueSnackbar(t(i18nKey), {
			variant: "warning",
		})
	}

	const onCheckoutButtonClick = () => {
		checkout(
			cart,
			checkoutContext,
			appContext,
			enqueueSnackbarTranslatedSnackbarWarning,
			NEXT_PUBLIC_HOST,
			setIsPrepaidDiscounted,
		)
	}

	const priceStyle = isLoading ? blurredStyle : {}

	return (
		<>
			<OrderOverview
				lineItems={lineItems}
				customLineItems={customLineItems}
				totalGrossCents={totalGrossCents}
				priceStyle={priceStyle}
				lockingSystems={lockingSystems}
			/>
			<Box mt={2}>
				{!hasCustomDesignKeysInCart(lineItems) && (
					<FormControlLabel
						control={
							<Checkbox
								checked={hasOrderAnnotation}
								onClick={toggleHasOrderAnnotation}
							/>
						}
						label="Ich habe Anmerkungen zur Bestellung"
					/>
				)}
				{(hasOrderAnnotation || hasCustomDesignKeysInCart(lineItems)) && (
					<TextField
						id="annotation"
						label={
							hasCustomDesignKeysInCart(lineItems)
								? "Bitte geben Sie an, welche Farben Ihre Designschlüssel haben sollen."
								: "Anmerkung"
						}
						variant="outlined"
						fullWidth
						multiline
						rows="4"
						value={orderAnnotation}
						onChange={onOrderAnnotationChange}
					/>
				)}
			</Box>

			<Box my={2}>
				<Button color="primary" disabled={isLoading} onClick={onCheckoutButtonClick}>
					Jetzt kaufen
				</Button>
			</Box>

			<AcceptTermsAndConditions />
		</>
	)
}

interface Props {
	checkoutContext: CheckoutContextState
	appContext: AppContextState
	cart: Cart
	setIsPrepaidDiscounted: React.Dispatch<React.SetStateAction<boolean>>
}

export default OrderOverviewAndCheckout
