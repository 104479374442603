import fetchCommerceToolsGraphQLApi from "../fetchCommerceToolsGraphQLApi"

import { Order, OrderMyCartCommand } from "@secureo/common/typings/generated/commercetools-graphql"

const createMyOrderFromCart = async (accessToken: string, draft: OrderMyCartCommand) => {
	const query = /* GraphQL */ `
		mutation createMyOrderFromCart($draft: OrderMyCartCommand!) {
			createMyOrderFromCart(draft: $draft, storeKey: "${process.env.NEXT_PUBLIC_COMMERCETOOLS_STORE_KEY}") {
				id
			}
		}
	`

	const data = (await fetchCommerceToolsGraphQLApi(accessToken, query, {
		draft,
	})) as {
		createMyOrderFromCart: Pick<Order, "id">
	}

	return data?.createMyOrderFromCart?.id ?? null
}

export default createMyOrderFromCart
